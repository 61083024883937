import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, unref as _unref, renderList as _renderList, Fragment as _Fragment, withCtx as _withCtx, resolveComponent as _resolveComponent, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "v-flex-column" }
const _hoisted_2 = { class: "v-interaction-header-container sp-interaction-details" }
const _hoisted_3 = { class: "v-header-left" }
const _hoisted_4 = { class: "v-interaction-title" }
const _hoisted_5 = {
  key: 0,
  class: "v-header-right"
}
const _hoisted_6 = { class: "v-careplan-review-section" }
const _hoisted_7 = { class: "v-careplan-review-interactions-title" }
const _hoisted_8 = { class: "v-careplan-review-interactions-content" }
const _hoisted_9 = ["innerHTML"]

import { storeToRefs } from 'pinia';
import useNeedsStore from '@/_shared/store/needs';
import {
  computed, PropType, ref,
} from 'vue';
import { ClientCagegory, Need, NeedReview } from '@/timeline/types/Need';
import use from '@/_shared/compositionApi';
import BaseCard from '@/_shared/components/display/BaseCard/BaseCard.vue';
import BaseIcon from '@/_shared/components/general/BaseIcon.vue';
import BaseModal from '@/_shared/components/modals/BaseModal.vue';
import BaseSelect from '@/_shared/components/inputs/BaseSelect.vue';
import BaseAlert from '@/_shared/components/feedback/BaseAlert.vue';
import useUserStore from '@/_shared/store/user';
import InteractionSection from '../InteractionSection.vue';
import CarePlanResponsiblePeople from './CarePlanResponsiblePeople.vue';
import CarePlanReviewItem from './CarePlanReviewItem.vue';
import LastClosedReview from './LastClosedReview.vue';
import LevelOfNeed from './LevelOfNeed.vue';
import RiskAssessment from './RiskAssessment.vue';
import PreviousReviews from './PreviousReviews.vue';
import NextReviewDue from './NextReviewDue.vue';
import EditCarePlan from './edit/EditCarePlan.vue';
import { buildNeedToEdit } from './edit/edit-utils';


export default /*@__PURE__*/_defineComponent({
  __name: 'CarePlanReview',
  props: {
  need: {
    type: Object as PropType<Need>,
    required: true,
  },
  hidePreviousReviews: {
    type: Boolean,
    default: false,
  },
  actionText: {
    type: String,
    default: '',
  },
  canPrint: {
    type: Boolean,
    default: true,
  },
  canEdit: {
    type: Boolean,
    default: false,
  },
  isEditing: {
    type: Boolean,
    default: false,
  },
  showActionButtons: {
    type: Boolean,
    default: false,
  },
  showUpdateButton: {
    type: Boolean,
    default: false,
  },
  reviewId: {
    type: Number,
    default: null,
  },
},
  emits: ['action', 'saved'],
  setup(__props, { emit: __emit }) {

const { translate } = use.helpers();

const needsStore = useNeedsStore();
const {
  fetchAllReviews,
  fetchVersion,
  getProvidedServicesForNeed,
  getCategories,
  save,
} = needsStore;
const {
  needVersion,
} = storeToRefs(needsStore);

const userStore = useUserStore();
const { featureToggles } = storeToRefs(userStore);
const { userHasPermission } = userStore;

const { clientStore } = use.store.clients();

const isVersionModalOpen = ref(false);

const toggleVersionModal = async (id?: number) => {
  if (id) {
    await fetchVersion({
      id: props.need.id,
      lastReviewId: id,
    });
    isVersionModalOpen.value = true;
  } else {
    isVersionModalOpen.value = false;
  }
};

const isMoveModalOpen = ref(false);
const categories = ref<ClientCagegory[]>([]);
const groupedCategories = computed(() => categories.value.map((c) => ({
  optGroup: c.name,
  options: c.clientSubcategories.map((s) => ({
    value: s.id,
    text: s.name,
  })),
})));

const toggleMoveModal = async () => {
  categories.value = await getCategories(props.need.clientId);
  isMoveModalOpen.value = !isMoveModalOpen.value;
};

const moveNeed = async () => {
  if (selectedSubCategoryId.value) {
    const needToSave = buildNeedToEdit(props.need);
    needToSave.clientSubcategoryId = selectedSubCategoryId.value;
    const result = await save(needToSave);
    await toggleMoveModal();
    window.dispatchEvent(new CustomEvent('careplanmoved', { detail: result }));
  }
};

const isArchiveAlertOpen = ref(false);

const toggleArchiveAlert = async () => {
  isArchiveAlertOpen.value = !isArchiveAlertOpen.value;
};

const archiveNeed = async () => {
  const needToSave = buildNeedToEdit(props.need);
  needToSave.archived = true;
  const result = await save(needToSave);
  await toggleArchiveAlert();
  window.dispatchEvent(new CustomEvent('careplanmoved', { detail: result }));
};

const risks = computed(() => props.need.riskAssessments.filter((r) => !r.archived).concat().sort((a, b) => {
  const idA = a.id || 0;
  const idB = b.id || 0;
  return idA - idB;
}));

const showPreviousReviews = computed(() => /* add whole logic */ !props.hidePreviousReviews);

const lastClosedReview = computed(() => props.need.reviews.find((r) => r.id === props.need.lastReviewId));

const providedServices = computed(() => getProvidedServicesForNeed(props.need));

const providedServicesForNeed = computed(() => props.need.needProvidedServices.map((nps) => ({
  id: nps.id,
  description: nps.description,
  name: providedServices.value?.find((service) => service.id === nps.providedServiceId)?.name,
})));

const print = () => {
  window.print();
  return false;
};

const props = __props;

const currentClientState = computed(() => clientStore.currentOrgUnitState(props.need.clientId as number).value);

const allReviews = ref<NeedReview[]>([]);
const reviews = computed(() => (allReviews.value.length ? allReviews.value : props.need.reviews));

const handleAllReviewsRequest = async () => {
  allReviews.value = await fetchAllReviews(props.need.id);
};

const emit = __emit;
const selectedSubCategoryId = ref<number>(props.need.clientSubcategoryId);

const isCarePlanLockdown = computed(() => featureToggles.value.aoCarePlanLockdown && currentClientState.value === 'left');

const printPermission = computed(() => props.canPrint || userHasPermission('updateNeeds'));
const archivePermission = computed(() => props.canEdit && !isCarePlanLockdown.value);
const movePermission = computed(() => props.canEdit && !isCarePlanLockdown.value && userHasPermission('manageCareplan'));

const updatePermission = computed(() => props.showUpdateButton || (!isCarePlanLockdown.value
  && (!(featureToggles.value.aoFabMenu && currentClientState.value === 'left') || !featureToggles.value.aoFabMenu)));

return (_ctx: any,_cache: any) => {
  const _component_CarePlanReview = _resolveComponent("CarePlanReview", true)!

  return (_openBlock(), _createElementBlock("div", null, [
    (__props.isEditing)
      ? (_openBlock(), _createBlock(EditCarePlan, {
          key: 0,
          theNeed: __props.need,
          onCancel: _cache[0] || (_cache[0] = ($event: any) => (emit('action', 0))),
          onSaved: _cache[1] || (_cache[1] = ($event: any) => (emit('saved'))),
          reviewId: __props.reviewId
        }, null, 8, ["theNeed", "reviewId"]))
      : (_openBlock(), _createBlock(BaseCard, {
          key: 1,
          headerBackground: true,
          padding: false,
          contentTopBorder: false,
          class: "v-careplan-review"
        }, {
          header: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, [
              _createElementVNode("div", _hoisted_2, [
                _createElementVNode("div", _hoisted_3, [
                  _createVNode(BaseIcon, {
                    name: "ncp-careplan",
                    size: "2x",
                    noHover: "",
                    compact: "",
                    backgroundColor: "black",
                    foregroundColor: "white",
                    foregroundHoverColor: "white",
                    class: "v-service-icon"
                  }),
                  _createElementVNode("div", _hoisted_4, [
                    _createElementVNode("div", null, _toDisplayString(__props.need.title), 1)
                  ])
                ]),
                (__props.showActionButtons)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                      (printPermission.value)
                        ? (_openBlock(), _createBlock(BaseIcon, {
                            key: 0,
                            name: "print",
                            onClick: print
                          }))
                        : _createCommentVNode("", true),
                      (archivePermission.value)
                        ? (_openBlock(), _createBlock(BaseIcon, {
                            key: 1,
                            name: "archive",
                            onClick: toggleArchiveAlert
                          }))
                        : _createCommentVNode("", true),
                      (movePermission.value)
                        ? (_openBlock(), _createBlock(BaseIcon, {
                            key: 2,
                            name: "long-arrow-right",
                            onClick: toggleMoveModal
                          }))
                        : _createCommentVNode("", true)
                    ]))
                  : _createCommentVNode("", true)
              ])
            ])
          ]),
          content: _withCtx(() => [
            _createElementVNode("div", null, [
              _createElementVNode("div", _hoisted_6, [
                _createVNode(NextReviewDue, {
                  title: _unref(translate)('need.next_review_on'),
                  value: __props.need.nextReviewOn,
                  showActionButton: updatePermission.value,
                  fallbackText: _unref(translate)('need.next_review_not_set'),
                  actionText: __props.actionText,
                  onAction: _cache[2] || (_cache[2] = ($event: any) => (emit('action', __props.need.id)))
                }, null, 8, ["title", "value", "showActionButton", "fallbackText", "actionText"])
              ]),
              _createVNode(CarePlanReviewItem, {
                title: _unref(translate)('need.identified_need'),
                value: __props.need.identifiedNeed,
                fallbackText: _unref(translate)('common.empty_field_data'),
                class: "v-careplan-review-section"
              }, null, 8, ["title", "value", "fallbackText"]),
              _createVNode(LevelOfNeed, {
                title: _unref(translate)('need.level_of_need'),
                levelOfNeed: __props.need.levelOfNeed,
                fallbackText: _unref(translate)('common.empty_field_data'),
                class: "v-careplan-review-section"
              }, null, 8, ["title", "levelOfNeed", "fallbackText"]),
              _createVNode(CarePlanReviewItem, {
                title: _unref(translate)('need.planned_outcomes'),
                value: __props.need.plannedOutcomes,
                fallbackText: _unref(translate)('common.empty_field_data'),
                class: "v-careplan-review-section"
              }, null, 8, ["title", "value", "fallbackText"]),
              _createVNode(CarePlanReviewItem, {
                title: _unref(translate)('need.how_to_achive_outcomes'),
                value: __props.need.howToAchiveOutcomes,
                fallbackText: _unref(translate)('common.empty_field_data'),
                class: "v-careplan-review-section"
              }, null, 8, ["title", "value", "fallbackText"]),
              _createVNode(InteractionSection, null, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_7, _toDisplayString(_unref(translate)('need.need_subscribed_service')), 1),
                  _createElementVNode("div", _hoisted_8, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(providedServicesForNeed.value, (service) => {
                      return (_openBlock(), _createElementBlock("div", {
                        key: service.id,
                        class: "v-careplan-review-interactions-item"
                      }, [
                        _createElementVNode("h4", null, _toDisplayString(service.name), 1),
                        _createElementVNode("div", {
                          innerHTML: service.description
                        }, null, 8, _hoisted_9)
                      ]))
                    }), 128))
                  ])
                ]),
                _: 1
              }),
              _createVNode(RiskAssessment, {
                risks: risks.value,
                class: "v-careplan-review-section"
              }, null, 8, ["risks"]),
              _createVNode(CarePlanResponsiblePeople, {
                "responsible-person-ids": __props.need.responsiblePersonIds
              }, null, 8, ["responsible-person-ids"]),
              (lastClosedReview.value)
                ? (_openBlock(), _createBlock(LastClosedReview, {
                    key: 0,
                    review: lastClosedReview.value,
                    class: "v-careplan-review-section"
                  }, null, 8, ["review"]))
                : _createCommentVNode("", true),
              _createVNode(NextReviewDue, {
                showActionButton: updatePermission.value,
                title: _unref(translate)('need.next_review_on'),
                value: __props.need.nextReviewOn,
                fallbackText: _unref(translate)('need.next_review_not_set'),
                actionText: props.actionText,
                onAction: _cache[3] || (_cache[3] = ($event: any) => (emit('action', __props.need.id))),
                class: "v-careplan-review-section"
              }, null, 8, ["showActionButton", "title", "value", "fallbackText", "actionText"]),
              (showPreviousReviews.value)
                ? (_openBlock(), _createBlock(PreviousReviews, {
                    key: 1,
                    need: __props.need,
                    reviews: reviews.value,
                    onFetchAllReviews: handleAllReviewsRequest,
                    onShowVersion: toggleVersionModal,
                    class: "no-printable"
                  }, null, 8, ["need", "reviews"]))
                : _createCommentVNode("", true),
              (isVersionModalOpen.value && _unref(needVersion))
                ? (_openBlock(), _createBlock(BaseModal, {
                    key: 2,
                    onCloseModal: toggleVersionModal,
                    confirmHidden: true,
                    header: false,
                    fullWidth: true,
                    width: 848
                  }, {
                    content: _withCtx(() => [
                      _createVNode(_component_CarePlanReview, {
                        need: _unref(needVersion),
                        hidePreviousReviews: true,
                        actionText: _unref(translate)('alert.close'),
                        onAction: toggleVersionModal,
                        showActionButtons: true,
                        canPrint: true,
                        showUpdateButton: true
                      }, null, 8, ["need", "actionText"])
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              (isMoveModalOpen.value)
                ? (_openBlock(), _createBlock(BaseModal, {
                    key: 3,
                    onCloseModal: toggleMoveModal,
                    onConfirm: moveNeed,
                    width: 900,
                    cancelHidden: false,
                    confirmText: _unref(translate)('common.save'),
                    contentHeight: 200,
                    title: _unref(translate)('need.move_care_plan')
                  }, {
                    content: _withCtx(() => [
                      _createVNode(BaseSelect, {
                        options: groupedCategories.value,
                        border: true,
                        boldLabel: "",
                        modelValue: selectedSubCategoryId.value,
                        "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((selectedSubCategoryId).value = $event)),
                        searchable: true
                      }, null, 8, ["options", "modelValue"])
                    ]),
                    _: 1
                  }, 8, ["confirmText", "title"]))
                : _createCommentVNode("", true),
              (isArchiveAlertOpen.value)
                ? (_openBlock(), _createBlock(BaseAlert, {
                    key: 4,
                    class: "v-integration-nhs-token-alert",
                    dangerAlert: false,
                    icon: "info",
                    onConfirmAlert: archiveNeed,
                    onCancelAlert: toggleArchiveAlert,
                    confirmText: _unref(translate)('common.archive')
                  }, {
                    header: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_unref(translate)('need.alerts.archive_need_confirm')), 1)
                    ]),
                    _: 1
                  }, 8, ["confirmText"]))
                : _createCommentVNode("", true)
            ])
          ]),
          _: 1
        }))
  ]))
}
}

})