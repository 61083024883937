import apiClient from '@/_shared/services/apiClient';
import { OrganisationUnit } from '@/_shared/types/organisationUnit';
import { promiseMemorise } from '@/_shared/services/UseUtils';

export type DivisionsApiResponse = {
  divisions: string[];
}

export type RegionsApiResponse = {
  regions: string[];
}

export type OrgUnitsApiResponse = {
  organisationUnits: OrganisationUnit[];
}
export async function fetchDivisions() {
  try {
    const response = await apiClient.get<DivisionsApiResponse>('/api/v2/organisation_units/divisions');
    return response.divisions;
  } catch (errorsResponse) {
    return [];
  }
}

export const memorizedDivisions = promiseMemorise(fetchDivisions);

export async function fetchRegions() {
  try {
    const response = await apiClient.get<RegionsApiResponse>('/api/v2/organisation_units/regions');
    return response.regions;
  } catch (errorsResponse) {
    return [];
  }
}

export const memorizedRegions = promiseMemorise(fetchRegions);

export async function fetchOrgUnits() {
  try {
    const response = await apiClient.get<OrgUnitsApiResponse>('/api/v2/organisation_units');
    return response.organisationUnits;
  } catch (errorsResponse) {
    return [];
  }
}

export const memorizedOrgUnits = promiseMemorise(fetchOrgUnits);
