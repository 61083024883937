import apiClient from '@/_shared/services/apiClient';

interface IMedicalRecordsResponse {
  error?: string | null;
}

export const fetchRecord = async <TRequest extends Record<string, unknown>, TResponse>(url: string, request: TRequest): Promise<TResponse | IMedicalRecordsResponse> => {
  try {
    return await apiClient.post<TResponse>(url, request);
  } catch (error) {
    return { error: `${error.response.data.error}` };
  }
};

export const fetchTosFile = async (integrationCodename: string): Promise<void> => {
  const url = '/api/v2/integrations/integration_tos_file';
  try {
    const blob = await apiClient.get<Blob>(url, { params: { integration_codename: integrationCodename }, responseType: 'blob' });
    const fileURL = URL.createObjectURL(blob);
    window.open(fileURL);
  } catch (error) {
    console.error('Error:', error);
  }
};
