import NotificationConfiguration
  from '@/notifications/types/NotificationConfiguration';
import apiClient from '@/_shared/services/apiClient';

export const saveNotificationConfiguration = async (notificationConfiguration: NotificationConfiguration): Promise<NotificationConfiguration> => {
  try {
    const url = '/api/v2/notifications/configurations';
    const data = { notification_configuration: notificationConfiguration };
    return apiClient.post<NotificationConfiguration>(url, data);
  } catch (error) {
    return error;
  }
};

export default saveNotificationConfiguration;
