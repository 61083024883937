import { defineStore } from 'pinia';
import { ref } from 'vue';
import { OrganisationRole } from '@/_shared/services/organisationRolesApi';
import { OrganisationUnit } from '@/_shared/types/organisationUnit';
import {
  memorizedDivisions,
  memorizedOrgUnits,
  memorizedRegions,
} from '@/_shared/services/organisationUnitsApi';

const useNotificationsStore = defineStore('notifications', () => {
  const selectedRole = ref<OrganisationRole | null>(null);
  const divisions = ref<string[]>([]);
  const regions = ref<string[]>([]);
  const orgUnits = ref<string[]>([]);
  const orgUnitsApiResponse = ref<OrganisationUnit[]>([]);
  const notificationsLoaded = ref<boolean>(false);

  const fetchNotificationSectionsOptions = async () => {
    divisions.value = await memorizedDivisions();
    regions.value = await memorizedRegions();
    orgUnitsApiResponse.value = await memorizedOrgUnits();
    if (orgUnitsApiResponse.value) {
      orgUnits.value = orgUnitsApiResponse.value.map((unit) => unit.name);
    }
    notificationsLoaded.value = true;
  };

  const selectRole = (role: string, roleId: number) => {
    selectedRole.value = { name: role, id: roleId };
  };

  const $reset = () => {
    selectedRole.value = null;
    notificationsLoaded.value = false;
  };

  return {
    $reset,
    selectRole,
    selectedRole,
    divisions,
    regions,
    orgUnits,
    notificationsLoaded,
    fetchNotificationSectionsOptions,
  };
});

export default useNotificationsStore;
