import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "v-dropdown-title" }
const _hoisted_2 = {
  key: 0,
  class: "v-mobile-dropdown-background"
}
const _hoisted_3 = { class: "v-mobile-dropdown-menu" }

import getDefaultLinks from '@/_shared/helpers/carerTabs';
import getDynamicLinks from '@/_shared/helpers/clientTabs';
import { TopBarLinks } from '@/_shared/types/baseTopBarLink';
import { computed, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import getMessagesLinks from '@/_shared/helpers/MessagesTabs';
import getLinks from '@/_shared/helpers/RolesAndNotificationsTabs';
import PersonMobileDropdownOption
  from '@/_shared/components/navigation/PersonMobileDropdownOption.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'PersonMobileDropdown',
  props: {
  personId: {
    type: Number,
    required: true,
  },
  type: {
    type: String,
    required: true,
  },
},
  setup(__props) {

const props = __props;
const route = useRoute();
const links = computed<TopBarLinks<true|false>>(() => {
  switch (props.type) {
    case 'client': {
      const { dynamicLinks } = getDynamicLinks(props.personId, window.familyPortal, route, true);
      return dynamicLinks.value;
    }
    case 'carer': {
      return getDefaultLinks(route);
    }
    case 'messages': {
      return getMessagesLinks();
    }
    case 'roles': {
      return getLinks();
    }
    default: {
      return [];
    }
  }
});

const router = useRouter();
const dropdownVisible = ref(false);

const selectedTab = computed(() => {
  if (links.value.length === 0) return '';

  const currentRoute = route.path;
  const currentTab = links.value.find((link) => {
    const linkRoute = router.resolve(link.to);

    if (!linkRoute.path) return false;
    return currentRoute === linkRoute.path || link.activeOnChildren?.value;
  });
  return currentTab ? currentTab.name : '';
});

const toggleDropdown = () => {
  dropdownVisible.value = !dropdownVisible.value;
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    onClick: toggleDropdown,
    class: "v-dropdown-container"
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createTextVNode(_toDisplayString(selectedTab.value) + " ", 1),
      _cache[0] || (_cache[0] = _createElementVNode("i", { class: "fa fa-caret-down icon" }, null, -1))
    ]),
    (dropdownVisible.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(links.value, (link) => {
              return (_openBlock(), _createBlock(PersonMobileDropdownOption, {
                link: link,
                key: link.name
              }, null, 8, ["link"]))
            }), 128))
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}
}

})