import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { BaseCard, CloseRightPanelIcon, BaseRightPanel } from '@/_shared/components';
import { computed } from 'vue';
import use from '@/_shared/compositionApi';
import { useRouter, useRoute } from 'vue-router';


export default /*@__PURE__*/_defineComponent({
  __name: 'ImageGalleryMain',
  props: {
  clientId: {
    type: [Number, String],
    required: false,
  },
  backRoute: {
    type: String,
    required: false,
  },
},
  setup(__props) {


const props = __props;

const { clientStore } = use.store.clients();
const router = useRouter();
const route = useRoute();

const onCloseComponent = () => {
  if (props.backRoute) {
    router.push({ name: props.backRoute, params: route.params, query: route.query });
  } else {
    router.back();
  }
};

const avatarPhoto = computed(() => {
  if (props.clientId) {
    return clientStore.photo(+props.clientId);
  }
  return undefined;
});


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(BaseRightPanel), { photo: avatarPhoto.value }, {
    default: _withCtx(() => [
      _createVNode(_unref(CloseRightPanelIcon), { onCloseRightPanel: onCloseComponent }),
      _createVNode(_unref(BaseCard), null, {
        header: _withCtx(() => _cache[0] || (_cache[0] = [])),
        content: _withCtx(() => _cache[1] || (_cache[1] = [])),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["photo"]))
}
}

})