import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, vShow as _vShow, withDirectives as _withDirectives, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "v-flex-column" }
const _hoisted_2 = { class: "v-new-notification-header-container" }
const _hoisted_3 = { class: "v-header-left" }
const _hoisted_4 = { class: "v-new-notification-title" }
const _hoisted_5 = { class: "v-content" }
const _hoisted_6 = { class: "v-new-notification-form" }
const _hoisted_7 = { class: "v-h4" }
const _hoisted_8 = { class: "v-radio-options" }
const _hoisted_9 = { class: "v-specific-section" }
const _hoisted_10 = { class: "v-specific-title" }
const _hoisted_11 = { class: "v-dataset-selected" }
const _hoisted_12 = { class: "v-form-actions" }

import {
  computed, onBeforeMount, reactive, ref, watch,
} from 'vue';
import { BaseCard, BaseCheckbox, CloseRightPanelIcon } from '@/_shared/components';
import use from '@/_shared/compositionApi';
import { useRouter } from 'vue-router';
import useNotificationsStore from '@/_shared/store/notifications';
import NotificationConfiguration from '@/notifications/types/NotificationConfiguration';
import {
  OptGroup, Options,
} from '@/_shared/types/baseSelect';
import DatasetModal from '@/_shared/components/modals/DatasetModal/DatasetModal.vue';
import useSelectDatasetModal from '@/timeline/interaction_parameters/AdvancedMultiselect/modal';
import {
  fetchEventsOptions,
} from '@/notifications/composables/notifications';
import useDatasetStore from '@/_shared/store/datasets';
import { storeToRefs } from 'pinia';
import {
  checkboxClicked,
  isChecked,
  sectionTitle,
  getDatasetOptions,
  Section,
  sections,
  getSectionOptions,
  selectedSpecificOptions,
  resetSelectedValues,
} from '@/notifications/services/notificationsFormHelper';
import { useDebounceFn } from '@vueuse/core';
import { saveNotificationConfiguration } from '@/notifications/services/notificationConfigurationApi';
import BaseRightPanel from '../../_shared/components/general/BaseRightPanel.vue';
import BaseSelect from '../../_shared/components/inputs/BaseSelect.vue';
import BaseButton from '../../_shared/components/buttons/BaseButton.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'NotificationsForm',
  setup(__props) {

const { translate } = use.helpers();
const router = useRouter();
const notificationsStore = useNotificationsStore();
const { notificationsLoaded } = storeToRefs(notificationsStore);
const { openModal, closeModal, showModal } = useSelectDatasetModal();
const { getEventCategoriesNames, getEventPWSDatasets, getSeveritiesDataset } = fetchEventsOptions();
const { initialized } = storeToRefs(useDatasetStore());
const currentSection = ref<Section>();
const savingInProgress = ref<boolean>(false);
const notificationConfiguration = reactive({} as NotificationConfiguration);
const divisionsOptions = ref<OptGroup[]>([]);
const regionsOptions = ref<OptGroup[]>([]);
const servicesOptions = ref<OptGroup[]>([]);
const eventCategoriesOptions = ref<OptGroup[]>([]);
const specificEventOptions = ref<OptGroup[]>([]);
const severitiesOptions = ref<OptGroup[]>([]);
const loaded = computed(() => notificationsLoaded.value);

onBeforeMount(() => {
  notificationsStore.fetchNotificationSectionsOptions();
  resetSelectedValues();
  setOptions();
});

const saveDisabled = computed(() => sections.some((section) => isChecked(section, 'specific') && selectedSpecificOptions[section].value.length === 0)
  || savingInProgress.value);

function setCurrentSection(section: Section) {
  currentSection.value = section;
}

function setOptions() {
  divisionsOptions.value = getSectionOptions(notificationsStore.divisions);
  regionsOptions.value = getSectionOptions(notificationsStore.regions);
  servicesOptions.value = getSectionOptions(notificationsStore.orgUnits);
  eventCategoriesOptions.value = getSectionOptions(getEventCategoriesNames.value, true);
  specificEventOptions.value = getDatasetOptions(getEventPWSDatasets.value);
  severitiesOptions.value = getDatasetOptions(getSeveritiesDataset.value);
}

function getOptions(section: Section) {
  switch (section) {
    case 'divisions':
      return divisionsOptions.value;
    case 'regions':
      return regionsOptions.value;
    case 'services':
      return servicesOptions.value;
    case 'eventCategories':
      return eventCategoriesOptions.value;
    case 'events':
      return specificEventOptions.value;
    case 'severity':
      return severitiesOptions.value;
    default:
      return [];
  }
}

const openOptionsModal = (readOnly: boolean, section: Section) => {
  setCurrentSection(section);
  openModal(readOnly);
};

watch(() => initialized.value, (newVal) => {
  if (!newVal) return;
  setOptions();
}, { deep: true });

const assignSelectedOptions = (selected: Options) => {
  if (currentSection.value) {
    selectedSpecificOptions[currentSection.value].value = selected;
  }
  closeModal();
};

const showSection = (section: Section): boolean => {
  switch (section) {
    case 'divisions':
      return notificationsStore.divisions && notificationsStore.divisions.length > 0;
    case 'regions':
      return notificationsStore.regions && notificationsStore.regions.length > 0;
    default:
      return true;
  }
};

const save = useDebounceFn(async () => {
  savingInProgress.value = true;
  notificationConfiguration.organisation_role_id = notificationsStore.selectedRole?.id || null;
  notificationConfiguration.superuser = notificationsStore.selectedRole?.name === 'Superuser';
  notificationConfiguration.divisions = selectedSpecificOptions.divisions.value as string[];
  notificationConfiguration.regions = selectedSpecificOptions.regions.value as string[];
  notificationConfiguration.services = selectedSpecificOptions.services.value as string[];
  notificationConfiguration.event_categories = selectedSpecificOptions.eventCategories.value as string[];
  notificationConfiguration.events = selectedSpecificOptions.events.value as string[];
  notificationConfiguration.severities = selectedSpecificOptions.severity.value as string[];

  await saveNotificationConfiguration(notificationConfiguration);
  savingInProgress.value = false;
  closeForm();
}, 300);

const closeForm = () => {
  router.push({ name: 'notifications' });
};


return (_ctx: any,_cache: any) => {
  return (loaded.value)
    ? (_openBlock(), _createBlock(BaseRightPanel, { key: 0 }, {
        default: _withCtx(() => [
          _createVNode(_unref(CloseRightPanelIcon), { onCloseRightPanel: closeForm }),
          _createVNode(_unref(BaseCard), {
            padding: false,
            "content-top-border": ""
          }, {
            header: _withCtx(() => [
              _createElementVNode("div", _hoisted_1, [
                _createElementVNode("div", _hoisted_2, [
                  _createElementVNode("div", _hoisted_3, [
                    _createElementVNode("div", _hoisted_4, [
                      _createElementVNode("div", null, _toDisplayString(_unref(translate)('notifications.new', { selected_role: _unref(notificationsStore).selectedRole?.name })), 1)
                    ])
                  ])
                ])
              ])
            ]),
            content: _withCtx(() => [
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("div", _hoisted_6, [
                  _createElementVNode("h4", _hoisted_7, _toDisplayString(_unref(translate)('notifications.select_criteria')), 1),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(sections), (section) => {
                    return _withDirectives((_openBlock(), _createElementBlock("div", {
                      key: section,
                      class: _normalizeClass(`v-form-section v-${section}`)
                    }, [
                      _createElementVNode("div", {
                        class: _normalizeClass(`v-${section}-title`)
                      }, _toDisplayString(_unref(sectionTitle)(section)), 3),
                      _createElementVNode("div", _hoisted_8, [
                        _createVNode(_unref(BaseCheckbox), {
                          radio: "",
                          label: _unref(translate)('common.all'),
                          value: "all",
                          checked: _unref(isChecked)(section, 'all'),
                          onClick: ($event: any) => (_unref(checkboxClicked)(section, 'all'))
                        }, null, 8, ["label", "checked", "onClick"]),
                        _createVNode(_unref(BaseCheckbox), {
                          radio: "",
                          label: _unref(translate)('notifications.specific'),
                          value: "specific",
                          checked: _unref(isChecked)(section, 'specific'),
                          onClick: ($event: any) => (_unref(checkboxClicked)(section, 'specific'))
                        }, null, 8, ["label", "checked", "onClick"])
                      ]),
                      _withDirectives(_createElementVNode("div", _hoisted_9, [
                        _createElementVNode("div", _hoisted_10, _toDisplayString(_unref(sectionTitle)(section, 'specific_')), 1),
                        _createElementVNode("div", _hoisted_11, [
                          _createVNode(BaseSelect, {
                            class: "v-data-set-container",
                            "model-value": _unref(selectedSpecificOptions)[section].value,
                            "show-options": false,
                            options: getOptions(section),
                            "custom-tags": true,
                            multiple: true,
                            canClear: false,
                            border: true,
                            onOpenModal: ($event: any) => (openOptionsModal(false, section)),
                            "remove-icon-display": "none",
                            searchable: false
                          }, null, 8, ["model-value", "options", "onOpenModal"])
                        ])
                      ], 512), [
                        [_vShow, _unref(isChecked)(section, 'specific')]
                      ])
                    ], 2)), [
                      [_vShow, showSection(section)]
                    ])
                  }), 128)),
                  (_unref(showModal) && currentSection.value)
                    ? (_openBlock(), _createBlock(DatasetModal, {
                        key: 0,
                        onCloseModal: _unref(closeModal),
                        title: _unref(sectionTitle)(currentSection.value, 'specific_'),
                        "selectable-entries": getOptions(currentSection.value),
                        "selected-entries": _unref(selectedSpecificOptions)[currentSection.value].value,
                        "search-input-placeholder": _unref(translate)('timeline.parameters.AdvancedMultiselect.modalSearch'),
                        "primary-text-key": "text",
                        onUpdatedSelectedEntries: assignSelectedOptions,
                        "selector-type": "multi",
                        "button-text": _unref(translate)('common.save')
                      }, null, 8, ["onCloseModal", "title", "selectable-entries", "selected-entries", "search-input-placeholder", "button-text"]))
                    : _createCommentVNode("", true)
                ]),
                _createElementVNode("div", _hoisted_12, [
                  _createVNode(BaseButton, {
                    class: "v-form-action-button sp-cancel",
                    "button-type": "secondary",
                    size: "medium",
                    disabled: false,
                    onClick: closeForm
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_unref(translate)('common.cancel')), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(BaseButton, {
                    class: "v-form-action-button sp-save",
                    "button-type": "primary",
                    size: "medium",
                    disabled: saveDisabled.value,
                    onClick: _unref(save),
                    colour: "black-text"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_unref(translate)('common.save')), 1)
                    ]),
                    _: 1
                  }, 8, ["disabled", "onClick"])
                ])
              ])
            ]),
            _: 1
          })
        ]),
        _: 1
      }))
    : _createCommentVNode("", true)
}
}

})