import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withDirectives as _withDirectives, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "resident-dashboard-section-icon" }
const _hoisted_2 = { class: "resident-dashboard-section-text" }
const _hoisted_3 = { class: "resident-dashboard-section-value sp-resident-dashboard-nourish-instances resident-dashboard-section-label-no-badge" }
const _hoisted_4 = { class: "v-resident-dashboard-nourish-instances-title-text" }
const _hoisted_5 = { class: "resident-dashboard-section-value v-resident-dashboard-nourish-instances-subtitle sp-resident-dashboard-subtitle" }
const _hoisted_6 = {
  key: 0,
  class: "v-resident-dashboard-plain-text"
}
const _hoisted_7 = {
  key: 1,
  class: "v-resident-dashboard-italic-text"
}
const _hoisted_8 = { class: "v-tooltip v-resident-dashboard-skin-and-wounds-scrollable-wrapper" }

import {
  ref, nextTick, onMounted, computed,
  watch,
} from 'vue';
import { NourishInstance, SkinInstance } from '@/_shared/types/NourishInstance';
// yarn type-check errors on masonry-layout
// eslint-disable-next-line
// @ts-ignore
import Masonry from 'masonry-layout';
import { useRoute, useRouter } from 'vue-router';
import BaseIcon from '@/_shared/components/general/BaseIcon.vue';
import { safeTranslate } from '@/_shared/translations/i18n';
import useEventListener from '@/_shared/Composables/event';
import { clientStore } from '@/_shared/store/clients';
import TeleportWrapper from '@/_shared/components/general/TeleportWrapper.vue';
import BaseTooltip from '@/_shared/components/feedback/BaseTooltip.vue';
import { storeToRefs } from 'pinia';
import useUserStore from '@/_shared/store/user';
import vBaseTooltip from '@/_shared/directives/BaseTooltip';
import instancesHelper from '@/_shared/helpers/useInstancesHelper';
import BodyMapTimeline from './BodyMap/BodyMapTimeline.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'BodyMapTimelineWrapper',
  props: {
  clientId: {
    type: String,
    required: true,
  },
},
  setup(__props) {

const { featureToggles } = storeToRefs(useUserStore());

const route = useRoute();
const router = useRouter();
const props = __props;

const hideSectionTooltip = ref(true);
const runTeleport = ref(false);
const masonry = ref<Masonry>();

const { groupInstancesByState } = instancesHelper();

const routeToInteraction = async (instance: NourishInstance) => {
  router.push({
    name: 'client.timeline.body_map.instance',
    params: { clientId: instance.clientId, instanceId: instance.id },
    force: true,
    query: route.query,
  });
};

const routeToBodymap = async () => {
  router.push({ name: 'client.timeline.body_map', force: true, query: route.query });
};

// HACK / TODO: Clean up teleportation re-flows to be less clunky
const initiateComponentTeleport = async () => {
  runTeleport.value = false;
  await nextTick();
  runTeleport.value = true;
  await nextTick();
  reflowMasonry();
};

onMounted(() => {
  const teleportDiv = document.getElementById('resident-dashboard-section-skin-instances');
  // If the component mounts we haven't already teleported, we should force a teleport
  if (teleportDiv && teleportDiv.childElementCount === 0) {
    initiateComponentTeleport();
  }
});

// If Angular is ready AFTER we've mounted, listen out for the dashboard ready event
useEventListener(window, 'dashboardViewContentLoaded', initiateComponentTeleport);

const updateMargin = (skinInstancesElement: HTMLElement) => {
  try {
    const firstInstanceElement = skinInstancesElement.getElementsByClassName('v-list-entry')[0] as HTMLElement;
    if (firstInstanceElement?.style?.marginTop !== '0') {
      firstInstanceElement.style.marginTop = '0';
    }
  } catch (e) {
    // Do nothing
  }
};

const resizeSkinInstancesList = () => {
  const skinInstancesElement = document.getElementById('resident-dashboard-section-skin-instances') as HTMLElement;

  if (skinInstancesElement?.childElementCount <= 0) {
    return;
  }

  updateMargin(skinInstancesElement);
  updateElementHeight(skinInstancesElement);
};

const handleScrollBar = () => {
  document.querySelectorAll('.v-resident-dashboard-skin-and-wounds-scrollable').forEach((element) => {
    if (instancesRequiringAttention.value <= 2) {
      element.classList.add('hide-scrollbar');
    } else {
      element.classList.remove('hide-scrollbar');
    }
  });
};

const runReflowMasonry = () => {
  if (!masonry.value) {
    masonry.value = new Masonry('.resident-dashboard-sections', {
      itemSelector: '.resident-dashboard-section',
      columnWidth: '.resident-dashboard-section',
      horizontalOrder: true,
      percentPosition: true,
      transitionDuration: 0,
      initLayout: true,
    });
  } else if (masonry.value.layout) {
    masonry.value.layout();
  }
};

const reflowMasonry = () => {
  // If angular hasn't laid out we don't want to try to reflow.
  if (!document.querySelector('.resident-dashboard-sections')) {
    return;
  }

  resizeSkinInstancesList();
  handleScrollBar();
  setTimeout(() => {
    // If there are no instances, remove the section
    const skinInstancesWrapper = document.getElementById('resident-dashboard-section-skin-instances') as HTMLElement;
    if (skinInstancesWrapper && skinInstancesWrapper.childElementCount <= 0) {
      skinInstancesWrapper.remove();
    }
  }, 1000);
  setTimeout(() => {
    // If there are no instances, remove the section
    runReflowMasonry();
  }, 5);
};

const skinInstances = computed(() => clientStore.skinInstances(Number(props.clientId)).value);
const groupedInstances = computed(() => groupInstancesByState<SkinInstance>(skinInstances.value, 'body_map.state'));
const showInstanceOnResidentDashboard = computed(() => featureToggles.value.skinManagementOnCritInfoDashboard && skinInstances.value.length);
const filteredInstances = computed(() => {
  let instances = [] as SkinInstance[];
  groupedInstances.value.forEach((instanceGroup) => {
    instances = instances.concat(instanceGroup.instances.filter((instance) => instance.state === 'active' || instance.state === 'new'));
  });
  return instances;
});
const instanceCount = computed(() => filteredInstances.value.length);
const instancesRequiringAttention = computed(() => filteredInstances.value.filter((instance) => instance.state === 'new'
    || (
      instance.state === 'active' && (
        (instance.missedInteractions?.length)
        || (
          !instance.interactions.some((interaction) => interaction.state === 'planned')
        )
      ))).length);

const calculateHeight = () => {
  let initialSize = 250;
  const lengthThatFits = 42;
  const specificTypeLength = filteredInstances.value[0].metadata.specificType.length;
  if (specificTypeLength > lengthThatFits) {
    initialSize += (Math.ceil(specificTypeLength / lengthThatFits) - 1) * 24;
  }
  return `${initialSize}px`;
};

const updateElementHeight = (skinInstancesElement: HTMLElement) => {
  let size = '32rem';
  switch (instancesRequiringAttention.value) {
    case 0:
      size = '80px';
      break;
    case 1:
      size = calculateHeight();
      break;
    case 2:
      size = '415px';
      break;
    default:
      size = '32rem';
      break;
  }

  if (skinInstancesElement?.style?.height !== size) {
    skinInstancesElement.style.height = size;
    skinInstancesElement.style.maxHeight = size;
  }
};

// Whenever the list size changes we may need to reflow masonry layout; Fixes the initial size problem
watch(filteredInstances, reflowMasonry);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    (runTeleport.value && showInstanceOnResidentDashboard.value)
      ? (_openBlock(), _createBlock(TeleportWrapper, {
          key: 0,
          to: "#resident-dashboard-section-skin-instances"
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, [
              _createVNode(BaseTooltip, {
                position: "left",
                tooltipText: _unref(safeTranslate)('client.dashboard.skin_and_wounds.icon_tooltip')
              }, {
                default: _withCtx(() => [
                  _createVNode(BaseIcon, {
                    name: "nsx-font-icon--body-map",
                    "background-shape": "circle",
                    "background-color": "black",
                    foregroundColor: "white",
                    class: "v-icon v-body-map-icon v-body-map-open v-resident-dashboard-body-map-icon",
                    height: "60px",
                    width: "60px",
                    style: {"margin-left":"-10px"},
                    noHover: ""
                  })
                ]),
                _: 1
              }, 8, ["tooltipText"])
            ]),
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("div", {
                  onClick: routeToBodymap,
                  class: "v-resident-dashboard-nourish-instances-title sp-resident-dashboard-title-link"
                }, [
                  _createVNode(BaseTooltip, {
                    position: "top",
                    tooltipText: _unref(safeTranslate)('client.dashboard.skin_and_wounds.title_tooltip')
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("span", _hoisted_4, _toDisplayString(_unref(safeTranslate)("client.dashboard.skin_and_wounds.title")) + " (" + _toDisplayString(instanceCount.value) + ")", 1)
                    ]),
                    _: 1
                  }, 8, ["tooltipText"])
                ])
              ]),
              _createElementVNode("div", _hoisted_5, [
                (instanceCount.value > 0)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(_unref(safeTranslate)("client.dashboard.skin_and_wounds.wounds_requiring_attention")) + " (" + _toDisplayString(instancesRequiringAttention.value) + ") ", 1))
                  : (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString(_unref(safeTranslate)("client.dashboard.skin_and_wounds.no_wounds_requiring_attention")), 1))
              ]),
              _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_8, [
                (instancesRequiringAttention.value > 0)
                  ? (_openBlock(), _createElementBlock("div", {
                      key: 0,
                      style: {"margin-top":"1rem"},
                      class: "v-resident-dashboard-skin-and-wounds-scrollable",
                      onMouseover: _cache[0] || (_cache[0] = ($event: any) => (hideSectionTooltip.value = false)),
                      onFocusin: _cache[1] || (_cache[1] = ($event: any) => (hideSectionTooltip.value = false)),
                      onMouseleave: _cache[2] || (_cache[2] = ($event: any) => (hideSectionTooltip.value = true)),
                      onFocusout: _cache[3] || (_cache[3] = ($event: any) => (hideSectionTooltip.value = true))
                    }, [
                      _createVNode(BodyMapTimeline, {
                        "client-id": __props.clientId,
                        "show-body-map-filter": false,
                        "list-only": true,
                        onSelectInstance: routeToInteraction
                      }, null, 8, ["client-id"])
                    ], 32))
                  : _createCommentVNode("", true)
              ])), [
                [_unref(vBaseTooltip), {tooltipText: _unref(safeTranslate)('client.dashboard.skin_and_wounds.section_tooltip'), position: 'left', hide: hideSectionTooltip.value}]
              ])
            ])
          ]),
          _: 1
        }))
      : _createCommentVNode("", true)
  ]))
}
}

})