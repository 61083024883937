import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, withDirectives as _withDirectives, withCtx as _withCtx, createSlots as _createSlots, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "v-body-map-list-entry" }

import { safeTranslate } from '@/_shared/translations/i18n';
import { computed, PropType } from 'vue';
import LocationIcon from '@/timeline/components/BodyMap/LocationIcon.vue';
import { SkinInstance } from '@/_shared/types/NourishInstance';
import InstanceListEntry from '@/_shared/components/display/InstanceListEntry.vue';
import BodyMapListEntryBadge from '@/timeline/components/BodyMap/BodyMapListEntryBadge.vue';
import { getUpdatedAgoText } from '@/_shared/helpers/useDateTimeHelper';
import useUserStore from '@/_shared/store/user';
import { storeToRefs } from 'pinia';
import { BaseIcon } from '@/_shared/components';
import { vBaseTooltip } from '@/_shared/directives/';
import { useRoute, useRouter } from 'vue-router';
import { hasImages } from '@/_shared/services/nourishInstancesApi';
import { computedAsync } from '@vueuse/core';
import InstanceStatusBadge from '../InstanceStatusBadge.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'BodyMapListEntry',
  props: {
    instance: {
      type: Object as PropType<SkinInstance>,
      required: true,
    },
    reference: {
      type: Boolean,
      default: false,
    },
    sideToShow: {
      type: String,
      default: 'all',
    },
    isInstanceView: {
      type: Boolean,
      default: false,
    },
    showLocationIcon: {
      type: Boolean,
      default: true,
    },
    showGalleryButton: {
      type: Boolean,
      default: false,
    },
    locationIconPlaceholder: {
      type: Boolean,
      default: false,
    },
  },
  emits: [
  'selectInstance',
],
  setup(__props, { emit: __emit }) {

const props = __props;

const emit = __emit;
const { featureToggles } = storeToRefs(useUserStore());
const router = useRouter();
const route = useRoute();

const isExtendedInfoEnable = computed(() => featureToggles.value.skinManagementOnCritInfoDashboard || false);
const missedInteractionCount = computed(() => props.instance.missedInteractions?.length ?? 0);
const hasMissedInteraction = computed(() => missedInteractionCount.value > 0);
const missedInteractionInfo = computed(() => safeTranslate('common.missed_interactions'));
const followUpNeeded = computed(() => (props.instance.state === 'active' && !props.instance.interactions.some((interaction) => interaction.state === 'planned')));
const identifiedWhen = computed(() => getUpdatedAgoText(new Date(props.instance?.createdAt)));
const showMissedInteractions = computed(() => hasMissedInteraction.value && !props.isInstanceView && isExtendedInfoEnable.value);
const showFollowUpNeeded = computed(() => followUpNeeded.value && !props.isInstanceView && isExtendedInfoEnable.value);
const showIdentifiedWhen = computed(() => props.instance.state === 'new' && !props.isInstanceView && isExtendedInfoEnable.value);
const showInstanceView = computed(() => props.isInstanceView && isExtendedInfoEnable.value);
const showGalleryButton = computedAsync(async () => {
  if (featureToggles.value.instancesGalleryView && props.showGalleryButton) {
    return hasImages(props.instance.clientId, props.instance.id);
  }
  return false;
}, false);

const showGalleryView = async () => {
  await router.push({
    name: 'client.timeline.body_map.instance.gallery_view',
    params: { ...route.params, instanceId: props.instance.id },
    query: route.query,
  });
};


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(InstanceListEntry, {
      title: _unref(safeTranslate)(`datasets.${__props.instance.metadata.specificType}`),
      "additional-headers": [_unref(safeTranslate)(`datasets.${__props.instance.metadata.location}`)],
      onClick: _cache[0] || (_cache[0] = ($event: any) => (emit('selectInstance'))),
      reference: __props.instance.reference,
      icon: __props.showLocationIcon
    }, _createSlots({
      footer: _withCtx(() => [
        (showInstanceView.value)
          ? (_openBlock(), _createBlock(InstanceStatusBadge, {
              key: 0,
              label: _unref(safeTranslate)(`body_map.state.${__props.instance.state}`)
            }, null, 8, ["label"]))
          : _createCommentVNode("", true),
        (showMissedInteractions.value)
          ? (_openBlock(), _createBlock(BodyMapListEntryBadge, {
              key: 1,
              label: missedInteractionInfo.value,
              counter: missedInteractionCount.value
            }, null, 8, ["label", "counter"]))
          : _createCommentVNode("", true),
        (showFollowUpNeeded.value)
          ? (_openBlock(), _createBlock(BodyMapListEntryBadge, {
              key: 2,
              label: _unref(safeTranslate)('body_map.follow_up_needed')
            }, null, 8, ["label"]))
          : _createCommentVNode("", true),
        (showIdentifiedWhen.value)
          ? (_openBlock(), _createBlock(BodyMapListEntryBadge, {
              key: 3,
              label: `${_unref(safeTranslate)('body_map.state.new')} ${identifiedWhen.value}`
            }, null, 8, ["label"]))
          : _createCommentVNode("", true),
        (_unref(showGalleryButton))
          ? _withDirectives((_openBlock(), _createBlock(_unref(BaseIcon), {
              key: 4,
              "data-id": "image-gallery-button",
              name: 'v-icon-image-gallery',
              onClick: _withModifiers(showGalleryView, ["stop"]),
              compact: true,
              "foreground-color": 'black',
              "background-color": 'pale-teal',
              square: "",
              "no-hover-background": "",
              noHover: "",
              size: "2x",
              style: {"margin-left":"6px"}
            }, null, 512)), [
              [_unref(vBaseTooltip), {
                    tooltipText: _unref(safeTranslate)('body_map.image_gallery_tooltip'),
                    position: 'right'
                  }]
            ])
          : _createCommentVNode("", true)
      ]),
      _: 2
    }, [
      (__props.showLocationIcon)
        ? {
            name: "customIcon",
            fn: _withCtx(() => [
              _createVNode(LocationIcon, {
                "location-number": __props.instance.locationInfo!.count,
                "placeholder-only": __props.locationIconPlaceholder,
                blue: __props.instance.locationInfo!.side !== 'back' && __props.sideToShow !== 'back',
                "lozenge-icon": true
              }, null, 8, ["location-number", "placeholder-only", "blue"])
            ]),
            key: "0"
          }
        : undefined
    ]), 1032, ["title", "additional-headers", "reference", "icon"])
  ]))
}
}

})