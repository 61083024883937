import { toast, ToastType } from '@/_shared/services/nourishHelpers';
import i18n from '@/_shared/translations/i18n';
import { fetchTosFile } from '../services/integrationsApi';
import Integration from '../types/integration';

export const downloadTos = async (integration: Integration) => {
  if (integration.integrationCodename) {
    const url = integration.tosUrl ? integration.tosUrl : '';
    const fileBlob: Blob|null = await fetchTosFile(integration.integrationCodename);
    if (fileBlob) {
      openFile(fileBlob, getFileNameFromTosUrl(url));
      return;
    }
    toast(i18n.global.t('ajax.errors.not_saved'), ToastType.Danger);
  }
};

const DEFAULT_SLA_TCS_FILE = 'Nourish_SLA_and_TCs.pdf';
export const getFileNameFromTosUrl = (tosUrl: string) => {
  if (!tosUrl || tosUrl === '') {
    return DEFAULT_SLA_TCS_FILE;
  }
  const splitUrl = tosUrl.split('/');
  return decodeURIComponent(splitUrl[splitUrl.length - 1]);
};

export const openFile = async (fileBlob: Blob, fileName: string) => {
  const link = document.createElement('a');
  link.href = window.URL.createObjectURL(fileBlob);
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
