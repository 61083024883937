import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "v-interaction-restricted-access-container" }

import use from '@/_shared/compositionApi';
import { computed } from 'vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'InteractionRestrictedAccess',
  props: {
  data: {
    type: Object,
    required: true,
  },
},
  setup(__props) {

const { translate } = use.helpers();
const props = __props;
const formatDateWithYear = (date: string | number | Date) => new Date(date).toLocaleDateString('en-GB', {
  day: '2-digit',
  month: 'short',
  year: 'numeric',
});
const formatDateWithoutYear = (date: string | number | Date) => new Date(date).toLocaleDateString('en-GB', {
  day: '2-digit',
  month: 'short',
});
const isSameDate = (startDate: Date, endDate: Date) => startDate.getFullYear() === endDate.getFullYear()
  && startDate.getMonth() === endDate.getMonth()
  && startDate.getDate() === endDate.getDate();
const formatDate = (startDate: string | number | Date, endDate: string | number | Date) => {
  if (!isSameDate(new Date(startDate), new Date(endDate))) {
    return `${formatDateWithoutYear(startDate)} - ${formatDateWithYear(endDate)}`;
  }
  return formatDateWithYear(startDate);
};
const orgName = computed(() => props.data?.orgUnit?.name);
const multipleInteractionWithOrgUnitAccess = () => translate('timeline.interaction.multiple_with_org_unit_access', {
  count: props.data?.count,
  care_home: orgName.value,
  date_range: `${formatDate(props.data?.startDate, props.data?.endDate)}`,
});
const singleInteractionWithOrgUnitAccess = () => translate('timeline.interaction.single_with_org_unit_access', {
  count: props.data?.count,
  care_home: orgName.value,
  date_range: `${formatDate(props.data?.startDate, props.data?.endDate)}`,
});
const singleInteractionWithoutOrgUnitAccess = () => translate('timeline.interaction.single_resticted_org_unit_access', {
  count: props.data?.count,
  date_range: `${formatDate(props.data?.startDate, props.data?.endDate)}`,
});
const multipleInteractionWithoutOrgUnitAccess = () => translate('timeline.interaction.multiple_resticted_org_unit_access', {
  count: props.data?.count,
  date_range: `${formatDate(props.data?.startDate, props.data?.endDate)}`,
});
const getOrgAccessInteractionMessage = () => {
  if (props.data?.count === 1) {
    return singleInteractionWithOrgUnitAccess();
  }
  return multipleInteractionWithOrgUnitAccess();
};
const getInteractionMessageWithoutOrgAccess = () => {
  if (props.data?.count === 1) {
    return singleInteractionWithoutOrgUnitAccess();
  }
  return multipleInteractionWithoutOrgUnitAccess();
};
const interactionRestrictMessage = computed(() => {
  if (orgName.value) {
    return getOrgAccessInteractionMessage();
  }
  return getInteractionMessageWithoutOrgAccess();
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _renderSlot(_ctx.$slots, "default", { class: "v-interaction-restricted-access-body" }, () => [
      _createTextVNode(_toDisplayString(interactionRestrictMessage.value), 1)
    ])
  ]))
}
}

})