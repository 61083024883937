import * as pbi from 'powerbi-client';
import { nextTick } from 'vue';
import useAnalyticsStore from '@/_shared/store/analytics';
import { storeToRefs } from 'pinia';

type DashboardDetails = {
    reportId: string;
    embedUrl: string;
    workspaceId: string;
    page?: string;
}

type EmbedConfig = {
  type: string;
  id: string;
  embedUrl: string;
  accessToken: string;
  tokenType: pbi.models.TokenType.Embed,
  settings: Record<string, unknown>
  pageName?: string;
}

export const powerBiService = () => new pbi.service.Service(pbi.factories.hpmFactory, pbi.factories.wpmpFactory, pbi.factories.routerFactory);

export const embedConfiguration = (dashboardDetails: DashboardDetails, token: string) => {
  const config = {
    type: 'report',
    id: dashboardDetails.reportId,
    embedUrl: dashboardDetails.embedUrl,
    workspaceId: dashboardDetails.workspaceId,
    tokenType: pbi.models.TokenType.Embed,
    accessToken: token,
    settings: {
      filterPaneEnabled: false,
      navContentPaneEnabled: false,
    },
  } as EmbedConfig;
  if (dashboardDetails.page) config.pageName = dashboardDetails.page;
  return config;
};

export const embedReport = (powerbi: pbi.service.Service, container: HTMLElement, dashboardDetails: DashboardDetails, token: string) => {
  const { embeddedReport } = storeToRefs(useAnalyticsStore());
  embeddedReport.value = powerbi.embed(container, embedConfiguration(dashboardDetails, token)) as pbi.Report;
};

export const setPage = async (powerbi: pbi.service.Service, container: HTMLElement, page: string) => {
  await nextTick();
  const report = powerbi.get(container) as pbi.Report;
  report.setPage(page);
};
