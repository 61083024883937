import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, vShow as _vShow, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "v-dataset-modal-outer-content-wrapper" }
const _hoisted_2 = {
  key: 0,
  class: "v-left-content-wrapper"
}
const _hoisted_3 = { class: "v-dataset-modal-right-content-wrapper" }
const _hoisted_4 = { class: "v-dataset-modal--content-wrapper" }
const _hoisted_5 = { class: "v-dataset-modal--search-wrapper" }
const _hoisted_6 = { class: "v-search-icon" }
const _hoisted_7 = { class: "v-data-set" }
const _hoisted_8 = { class: "v-dataset-modal--list-wrapper" }
const _hoisted_9 = { class: "v-common-options" }
const _hoisted_10 = { class: "v-options-title" }
const _hoisted_11 = { class: "v-dataset-entries" }
const _hoisted_12 = { class: "v-all-options" }
const _hoisted_13 = { class: "v-options-title" }
const _hoisted_14 = { class: "v-dataset-entries" }

import {
  computed, inject, PropType, Ref, ref, toRef, watch,
} from 'vue';
import BaseInput from '@/_shared/components/inputs/BaseInput.vue';
import BaseIcon from '@/_shared/components/general/BaseIcon.vue';
import BaseSelect from '@/_shared/components/inputs/BaseSelect.vue';
import { CompositeOption, DataSetCompositeOption } from '@/_shared/types/baseSelect';
import use from '@/_shared/compositionApi';
import { DataSetModalConfig } from '@/_shared/components/modals/DatasetModal/type/DataSetModal';
import { syncRef } from '@vueuse/core';
import DatasetModalEntry from './DatasetModalEntry.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'DatasetModalContent',
  props: {
  searchInputPlaceholder: {
    type: String,
  },
  selectableEntries: {
    type: Object as PropType<DataSetCompositeOption[]>,
    required: true,
  },
  primaryTextKey: {
    type: String as PropType<keyof DataSetCompositeOption>,
    required: true,
  },
  secondaryTextKey: {
    type: String,
  },
  selectedEntries: {
    type: Object as PropType<string[]>,
    required: true,
  },
  selectorType: {
    type: String,
    default: 'single',
  },
  showOptions: {
    type: Boolean,
    default: false,
  },
  isSingle: {
    type: Boolean,
    default: false,
  },
},
  emits: ['selectedEntries'],
  setup(__props, { emit: __emit }) {

const props = __props;
const modalConfig = inject('dataSetModalConfig', undefined) as DataSetModalConfig|undefined;

const emit = __emit;
const { translate } = use.helpers();
const localSelectableEntries : Ref<DataSetCompositeOption[]> = ref(props.selectableEntries);
syncRef(toRef(props, 'selectableEntries'), localSelectableEntries, { direction: 'ltr' });
const searchTerm = ref('');
const localSelectedEntries = ref(props.selectedEntries);
const filteredSelectableEntries = ref<DataSetCompositeOption[]>(localSelectableEntries.value);

const allOptionsGroup = computed(() => {
  const options = filteredSelectableEntries.value.filter((option) => option.group === translate('common.all_options'));
  return sortOptionsBy(options, modalConfig?.sortOptionsBy || 'text' as keyof CompositeOption);
});

const sortOptionsBy = (options: DataSetCompositeOption[], property : keyof CompositeOption = 'value') => options.sort((a: DataSetCompositeOption, b: DataSetCompositeOption) => (
  a[property]!.toString().localeCompare(b[property]!.toString(), undefined, { numeric: true, sensitivity: 'base' })));

const commonOptionsGroup = computed(
  () => filteredSelectableEntries.value.filter((option) => option.group === translate('common.common_options')),
);

const searchInputClass = computed(() => `v-${props.selectorType} v-dataset-modal--search-input-field`);

const allCommonOptionsSelected = computed(() => commonOptionsGroup.value.every(
  (option) => localSelectedEntries.value.includes(option.value.toString()),
));

const searchTermsDictionary: { [key: string]: RegExp }| undefined = modalConfig?.searchTermsDictionary;

const searchTermMatch = computed(() => {
  if (!(searchTerm.value && searchTermsDictionary)) return '';
  const term = searchTerm.value.toLowerCase();
  for (let i = 0; i < Object.entries(searchTermsDictionary).length; i += 1) {
    const [synonym, matchRegExp] = Object.entries(searchTermsDictionary)[i];
    if (matchRegExp.test(term)) {
      return term.replace(matchRegExp, synonym);
    }
  }
  return '';
});

const entryMatchesSearchTerm = (entry: DataSetCompositeOption) => entry.text
  && (entry.text.toLowerCase().includes(searchTerm.value.toLowerCase())
      || (searchTermMatch.value && entry.text.toLowerCase().includes(searchTermMatch.value.toLowerCase())));

const entryPrimaryText = (entry: DataSetCompositeOption) => entry[props.primaryTextKey]?.toString();

const toggleSelectEntry = (entryName: string) => {
  if (localSelectedEntries.value.includes(entryName)) {
    const removeIndex = localSelectedEntries.value.findIndex((entry) => entry === entryName);
    localSelectedEntries.value.splice(removeIndex, 1);
  } else if (props.isSingle) {
    localSelectedEntries.value.splice(0, localSelectedEntries.value.length);
    localSelectedEntries.value.push(entryName);
  } else {
    localSelectedEntries.value.push(entryName);
  }
};

const filterEntries = () => {
  if (!localSelectableEntries.value || !filteredSelectableEntries.value) {
    return;
  }
  filteredSelectableEntries.value = localSelectableEntries.value.filter(
    (entry: DataSetCompositeOption) => entryMatchesSearchTerm(entry) && !entry.disabled,
  );
};
filterEntries();

const toggleGroup = computed(() => !(allCommonOptionsSelected.value
  || searchTerm.value.length));

const entrySelected = ((entry: DataSetCompositeOption) => localSelectedEntries.value
  && localSelectedEntries.value.includes(String(entry.value)));

function showAvatar() {
  return props.selectorType === 'multi_person' || props.selectorType === 'single_person';
}

watch(localSelectedEntries, (newSelectedEntries) => {
  emit('selectedEntries', newSelectedEntries);
}, {
  deep: true,
});

const filter = (acceptedValues : string[]) => {
  localSelectableEntries.value = props.selectableEntries.filter((entry) => acceptedValues.includes(entry.value as string));
  filteredSelectableEntries.value = localSelectableEntries.value;
  searchTerm.value = '';
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_unref(modalConfig)?.leftContentComponent)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_openBlock(), _createBlock(_resolveDynamicComponent(_unref(modalConfig).leftContentComponent), {
            onFilter: filter,
            selectedEntries: localSelectedEntries.value,
            "onUpdate:selectedEntries": _cache[0] || (_cache[0] = ($event: any) => ((localSelectedEntries).value = $event))
          }, null, 40, ["selectedEntries"]))
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", {
            class: _normalizeClass(searchInputClass.value)
          }, [
            _createElementVNode("div", _hoisted_6, [
              _createVNode(BaseIcon, {
                name: "search no-margin no-hover",
                size: "lg",
                foregroundColor: "grey"
              })
            ]),
            _createVNode(BaseInput, {
              class: "v-dataset-search",
              border: false,
              modelValue: searchTerm.value,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((searchTerm).value = $event)),
              placeholder: __props.searchInputPlaceholder,
              onInput: filterEntries
            }, null, 8, ["modelValue", "placeholder"])
          ], 2)
        ]),
        _createElementVNode("div", _hoisted_7, [
          _createVNode(BaseSelect, {
            class: "v-data-set-container sp-multi-value-box",
            modelValue: localSelectedEntries.value,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((localSelectedEntries).value = $event)),
            options: __props.selectableEntries,
            "custom-tags": true,
            "show-options": __props.showOptions,
            multiple: true,
            searchable: false
          }, null, 8, ["modelValue", "options", "show-options"])
        ]),
        _createElementVNode("div", _hoisted_8, [
          _withDirectives(_createElementVNode("div", _hoisted_9, [
            _createElementVNode("div", _hoisted_10, [
              _createElementVNode("h5", null, [
                _createElementVNode("strong", null, _toDisplayString(_unref(translate)('common.common_options')) + ":", 1)
              ])
            ]),
            _createElementVNode("div", _hoisted_11, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(commonOptionsGroup.value, (entry) => {
                return (_openBlock(), _createBlock(DatasetModalEntry, {
                  key: entry['value'],
                  entry: entry,
                  isSingle: __props.isSingle,
                  selectorType: __props.selectorType,
                  onToggleSelectEntry: toggleSelectEntry,
                  primaryText: entryPrimaryText(entry),
                  isSelected: entrySelected(entry),
                  showAvatar: showAvatar()
                }, null, 8, ["entry", "isSingle", "selectorType", "primaryText", "isSelected", "showAvatar"]))
              }), 128))
            ])
          ], 512), [
            [_vShow, toggleGroup.value]
          ]),
          _createElementVNode("div", _hoisted_12, [
            _createElementVNode("div", _hoisted_13, [
              _createElementVNode("h5", null, [
                _createElementVNode("strong", null, _toDisplayString(_unref(translate)('common.all_options')) + ":", 1)
              ])
            ]),
            _createElementVNode("div", _hoisted_14, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(allOptionsGroup.value, (entry) => {
                return (_openBlock(), _createBlock(DatasetModalEntry, {
                  key: entry['value'],
                  entry: entry,
                  isSingle: __props.isSingle,
                  selectorType: __props.selectorType,
                  onToggleSelectEntry: toggleSelectEntry,
                  primaryText: entryPrimaryText(entry),
                  isSelected: entrySelected(entry),
                  showAvatar: showAvatar()
                }, null, 8, ["entry", "isSingle", "selectorType", "primaryText", "isSelected", "showAvatar"]))
              }), 128))
            ])
          ])
        ])
      ])
    ])
  ]))
}
}

})