import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { safeTranslate } from '@/_shared/translations/i18n';
import {
  computed, onMounted, PropType, ref, watch,
} from 'vue';
import { CoercedMultiSelect, Parameter } from '@/timeline/types/Parameter';
import useDatasetStore from '@/_shared/store/datasets';
import { CompositeOption, OptGroup } from '@/_shared/types/baseSelect';
import AdvancedMultiselect from '@/timeline/interaction_parameters/AdvancedMultiselect/AdvancedMultiselect.vue';
import { Dataset } from '@/timeline/types/Dataset';
import { storeToRefs } from 'pinia';
import useCurrentInteractionStore from '@/timeline/store/currentInteractionStore';
import { mapOptions } from '@/timeline/helper/parametersHelper';


export default /*@__PURE__*/_defineComponent({
  __name: 'DataSetPicker',
  props: {
  parameter: {
    type: Object as () => Parameter,
    required: true,
  },
  readOnly: {
    type: Boolean,
    default: false,
  },
  selectorType: {
    type: String as PropType<'single' | 'multi'>,
    required: true,
  },
  datasetCodenames: {
    type: Array as PropType<string[]>,
    required: true,
  },
  noPreselectedAnswer: {
    type: Boolean,
    default: false,
  },
},
  setup(__props) {

const props = __props;

const { getDatasets, getDataset } = useDatasetStore();
const { initialized } = storeToRefs(useDatasetStore());

const currentInteractionStore = useCurrentInteractionStore();
const { isClosed, initialInteraction } = storeToRefs(currentInteractionStore);
const { setCoercedValueByParameterId } = currentInteractionStore;

const filterDataset = computed(() => {
  const filterName = props.parameter.config?.filter;
  if (!filterName) return null;
  return getDataset(filterName).value;
});

const filteredDatasets = computed(() => getDatasets(props.datasetCodenames));

const filterMapDataSetOptions = (optionItems: string[], filterItems: string[] | undefined, translatable: boolean): CompositeOption[] => {
  if (filterItems) {
    optionItems = optionItems.filter((val) => filterItems.includes(val));
  }

  return optionItems.map((item) => ({
    value: item,
    text: translatable ? safeTranslate(`datasets.${item}`) : item,
  }));
};

const datasetOptions = ref([] as OptGroup[]);
const valuesNotInOptions = ref<string[]>([]);

watch(() => initialized.value, (newVal) => {
  if (!newVal) return;
  setDatasetOptions();
}, { deep: true });

onMounted(() => {
  setDatasetOptions();
});

const setDatasetOptions = () => {
  const commonOptions: CompositeOption[] = [];
  const allOptions: CompositeOption[] = [];
  Object.values(filteredDatasets.value).forEach((dataset: Dataset) => {
    commonOptions.push(...filterMapDataSetOptions(dataset?.favourites || [], filterDataset.value?.favourites, dataset?.translatable ?? false));
    allOptions.push(...filterMapDataSetOptions(dataset?.items || [], filterDataset.value?.items, dataset?.translatable ?? false));
  });
  const originalCoercedValue = props.parameter.coercedValue as CoercedMultiSelect;
  if (props.parameter.config?.filter) {
    removeFilteredOutFromCoercedValue(allOptions.map((option) => option.text));
  } else if (!props.parameter.config?.filter && allOptions.length && originalCoercedValue && originalCoercedValue.values.length) {
    const optionValues = allOptions.map((option) => option.value) as string[];
    valuesNotInOptions.value = originalCoercedValue.values.filter((value) => !optionValues.includes(value));
    if (valuesNotInOptions.value.length) {
      previouslySelectedOptions.value = valuesNotInOptions.value;
      allOptions.push(...disabledOptions(valuesNotInOptions.value));
    }
  }
  datasetOptions.value = mapOptions(commonOptions, allOptions);
};

const disabledOptions = (values: string[]): CompositeOption[] => values.map((value) => ({
  value,
  text: value,
  disabled: true,
}));

const removeFilteredOutFromCoercedValue = (options: string[]) => {
  if (isClosed.value) return;
  const originalCoercedValue = initialInteraction.value.parameters?.find((p: Parameter) => p.id === props.parameter.id)?.coercedValue as CoercedMultiSelect;
  if (!originalCoercedValue || !originalCoercedValue.values.length) return;
  previouslySelectedOptions.value = options.filter((option) => originalCoercedValue.values.includes(option));
  setCoercedValueByParameterId(Number(props.parameter.id), { ...originalCoercedValue, values: previouslySelectedOptions.value });
};

const previouslySelectedOptions = ref<string[]>([]);


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(AdvancedMultiselect, {
    options: datasetOptions.value,
    parameter: __props.parameter,
    "read-only": __props.readOnly,
    "selector-type": __props.selectorType,
    "no-preselected-answer": __props.noPreselectedAnswer,
    "previously-selected-options-from-dataset": previouslySelectedOptions.value,
    "values-not-in-options": valuesNotInOptions.value
  }, null, 8, ["options", "parameter", "read-only", "selector-type", "no-preselected-answer", "previously-selected-options-from-dataset", "values-not-in-options"]))
}
}

})