import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "v-modal-container"
}
const _hoisted_2 = {
  key: 0,
  class: "v-modal-header"
}
const _hoisted_3 = { class: "v-modal-header-left" }
const _hoisted_4 = {
  key: 0,
  class: "v-modal-icon"
}
const _hoisted_5 = {
  key: 1,
  class: "v-modal-icon"
}
const _hoisted_6 = { key: 2 }
const _hoisted_7 = ["src", "alt"]
const _hoisted_8 = { class: "v-modal-header-right" }
const _hoisted_9 = {
  key: 1,
  class: "v-modal-footer"
}
const _hoisted_10 = { class: "v-button-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseIcon = _resolveComponent("BaseIcon")!
  const _component_BaseButton = _resolveComponent("BaseButton")!
  const _component_TeleportWrapper = _resolveComponent("TeleportWrapper")!

  return (_openBlock(), _createBlock(_component_TeleportWrapper, null, {
    default: _withCtx(() => [
      _createElementVNode("div", {
        class: _normalizeClass(["v-modal-backdrop", _ctx.modalClass]),
        onClick: _cache[3] || (_cache[3] = _withModifiers(($event: any) => (_ctx.$emit('closeModal')), ["self"]))
      }, [
        _renderSlot(_ctx.$slots, "backdrop", {}, undefined, true),
        (!_ctx.cardDisabled)
          ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
              _createElementVNode("div", {
                class: "v-modal-card",
                style: _normalizeStyle(_ctx.cardStyle)
              }, [
                (_ctx.header)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                      _createElementVNode("div", _hoisted_3, [
                        (_ctx.icon)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                              _createElementVNode("i", {
                                class: _normalizeClass(`${_ctx.icon}`)
                              }, null, 2)
                            ]))
                          : (_ctx.iconSvg)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                                _createVNode(_component_BaseIcon, {
                                  name: _ctx.iconSvg,
                                  noHover: "",
                                  compact: "",
                                  width: "36px",
                                  height: "36px"
                                }, null, 8, ["name"])
                              ]))
                            : (_openBlock(), _createElementBlock("div", _hoisted_6, [
                                (_ctx.iconUrl)
                                  ? (_openBlock(), _createElementBlock("img", {
                                      key: 0,
                                      src: _ctx.iconUrl,
                                      class: "v-service-icon",
                                      alt: `${_ctx.iconUrl} icon`
                                    }, null, 8, _hoisted_7))
                                  : _createCommentVNode("", true)
                              ])),
                        _createElementVNode("div", {
                          class: _normalizeClass(["v-modal-title", {'v-modal-dataset-title': _ctx.isDatasetModal}])
                        }, _toDisplayString(_ctx.title), 3)
                      ]),
                      _createElementVNode("div", _hoisted_8, [
                        _renderSlot(_ctx.$slots, "header", {}, undefined, true),
                        _createVNode(_component_BaseIcon, {
                          class: "v-close-button",
                          transparent: true,
                          name: "times",
                          size: "lg",
                          closeButtonBlack: true,
                          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('closeModal')))
                        })
                      ])
                    ]))
                  : _createCommentVNode("", true),
                _createElementVNode("div", {
                  class: "v-modal-content",
                  style: _normalizeStyle(_ctx.contentStyle)
                }, [
                  _renderSlot(_ctx.$slots, "content", {}, undefined, true)
                ], 4),
                (_ctx.footer)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                      _createElementVNode("div", _hoisted_10, [
                        (!_ctx.cancelHidden)
                          ? (_openBlock(), _createBlock(_component_BaseButton, {
                              key: 0,
                              onClicked: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('closeModal'))),
                              class: "v-cancel-button",
                              "button-type": _ctx.fromRestoreModal ? 'secondary' : 'primary'
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.cancelText), 1)
                              ]),
                              _: 1
                            }, 8, ["button-type"]))
                          : _createCommentVNode("", true),
                        (!_ctx.confirmHidden)
                          ? (_openBlock(), _createBlock(_component_BaseButton, {
                              key: 1,
                              disabled: _ctx.confirmDisabled,
                              onClicked: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('confirm'))),
                              "button-type": "primary",
                              class: "sp-v-confirm-button"
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.confirmText), 1)
                              ]),
                              _: 1
                            }, 8, ["disabled"]))
                          : _createCommentVNode("", true)
                      ])
                    ]))
                  : _createCommentVNode("", true)
              ], 4)
            ]))
          : _createCommentVNode("", true)
      ], 2)
    ]),
    _: 3
  }))
}