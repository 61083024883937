import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

import { BaseCircleButton } from '@/_shared/components';
import { vBaseTooltip } from '@/_shared/directives';
import { computed, onBeforeMount } from 'vue';
import useUserStore from '@/_shared/store/user';
import use from '@/_shared/compositionApi';
import { isMobileView, resizeHandler } from '@/_shared/helpers/mobileViewHelper';
import useEventListener from '@/_shared/Composables/event';
import useNotificationsStore from '@/_shared/store/notifications';
import { useRouter } from 'vue-router';


export default /*@__PURE__*/_defineComponent({
  __name: 'NotificationsPlusButton',
  setup(__props) {

const { translate } = use.helpers();
const { userHasPermission } = useUserStore();
const notificationsStore = useNotificationsStore();
const router = useRouter();

onBeforeMount(() => {
  notificationsStore.$reset();
});

const showAddBtn = computed(() => userHasPermission('manageRoles') && notificationsStore.selectedRole && router.currentRoute.value.name === 'notifications');

const buttonPosition = computed(() => (
  isMobileView.value ? 'relative' : 'absolute'
));
const tooltipPosition = computed(() => (isMobileView.value ? 'top' : 'left'));
useEventListener(window, 'resize', resizeHandler);

function showNotificationsForm() {
  router.push({ name: 'notifications.new' });
}

return (_ctx: any,_cache: any) => {
  return (showAddBtn.value)
    ? _withDirectives((_openBlock(), _createBlock(_unref(BaseCircleButton), {
        key: 0,
        class: "sp-add-button",
        top: "19px",
        right: "71px",
        left: "auto",
        position: buttonPosition.value,
        onClick: showNotificationsForm
      }, null, 8, ["position"])), [
        [_unref(vBaseTooltip), { tooltipText: _unref(translate)('notifications.add_notification'),
                      position: tooltipPosition.value }]
      ])
    : _createCommentVNode("", true)
}
}

})