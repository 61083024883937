import { RouteAuthContext } from '@/_shared/router/types';
import componentBaseOnFT from '@/_shared/router/ComponentHelper';
import ClientTypesMain from '@/client_types/ClientTypesMain.vue';
import IntegrationsMain from '@/integrations/IntegrationsMain.vue';
import NotificationsTopBar from '@/notifications/components/NotificationsTopBar.vue';
import BasePlaceHolderView from '@/_shared/components/routing/BasePlaceHolderView.vue';
import DataRetentionLeftPanel from '@/data_retention/DataRetentionLeftPanel.vue';
import BaseEmptyView from '@/_shared/components/routing/BaseEmptyView.vue';
import DeletedClients from '@/data_retention/DeletedClients.vue';
import Report from '@/data_retention/RetentionReport.vue';
import ReportList from '@/reports/ReportList.vue';
import { loadModuleLanguageFileAsync } from '@/_shared/translations/i18nLoading';
import PendingDeletions from '@/data_retention/PendingDeletions.vue';
import useUserStore from '@/_shared/store/user';
import useReportStore from '@/_shared/store/reports';
import NewReport from '@/reports/NewReport.vue';
import useDataRetentionStore from '../store/dataRetention';

export const adminRoutes = [
  {
    path: '/client_types',
    name: 'admin.clientTypes',
    component: ClientTypesMain,
  },
  {
    path: '/integrations',
    name: 'admin.integrations',
    component: IntegrationsMain,
  },
  {
    path: '/data_retention',
    components: {
      TopBar: BaseEmptyView,
    },
    beforeEnter: async () => {
      useDataRetentionStore().reset();
      await loadModuleLanguageFileAsync('data_retention');
      useDataRetentionStore().fetchInitialData();
      useDataRetentionStore().fetchDeletedClients();
    },
    children: [
      {
        path: '',
        components: {
          SideBar: DataRetentionLeftPanel,
        },
        children: [
          {
            path: '',
            name: 'admin.data_retention',
            component: BasePlaceHolderView,
          },
          {
            path: 'pending',
            name: 'admin.data_retention.pending',
            component: PendingDeletions,
          },
          {
            path: 'report',
            name: 'admin.data_retention.report',
            component: Report,
          },
          {
            path: 'deleted',
            name: 'admin.data_retention.deleted',
            component: DeletedClients,
          },
        ],
      },
    ],
  },
  {
    path: '/administration',
    component: BasePlaceHolderView,
    meta: { isAngular: true },
    children: [
      {
        path: 'group_services',
        name: 'admin.groupServices',
        component: BasePlaceHolderView,
      },
      {
        path: 'services',
        name: 'admin.services',
        component: BasePlaceHolderView,
      },
    ],
  },
  {
    path: '/reports',
    children: [
      {
        path: '',
        name: 'admin.reports',
        component: async () => componentBaseOnFT('vueReports', ReportList),
        beforeEnter: async () => {
          await useReportStore().fetchAll();
        },
      },
      {
        path: 'new',
        name: 'admin.reports.new',
        component: async () => componentBaseOnFT('vueReports', NewReport),
        beforeEnter: async () => {
          useReportStore().buildNewReport(useUserStore().currentOrgUnitId);
        },
      },
    ],
  },
  {
    path: '/permissions',
    name: 'admin.permissions',
    meta: { isAngular: true },
    component: BasePlaceHolderView,
  },
  {
    path: '/roles',
    components: {
      TopBar: NotificationsTopBar,
    },
    beforeEnter: async () => {
      await loadModuleLanguageFileAsync('notifications');
    },
    meta: { needSyncAngular: true },
    children: [
      {
        path: '',
        name: 'admin.roles',
        component: BasePlaceHolderView,
      },
    ],
  },
  {
    path: '/data_exports',
    name: 'admin.dataExports',
    meta: { isAngular: true },
    component: BasePlaceHolderView,
  },
  {
    path: '/device_management',
    name: 'admin.deviceManagement',
    component: BasePlaceHolderView,
    meta: { isAngular: true },
    children: [
      {
        path: ':state',
        name: 'admin.deviceManagement.state',
        component: BasePlaceHolderView,
      },
    ],
  },
];

export const allowedAdminRoutes = (authContext: RouteAuthContext) => {
  const allowedRoutes: string[] = [];

  if (authContext.isImpersonated()
    || (authContext.getFeatureToggle('clientTypesUi') && authContext.isSuperuser())) {
    allowedRoutes.push('admin.clientTypes');
  }

  if (authContext.userHasPermission('manageRoles')) {
    allowedRoutes.push('admin.roles');
  }

  if (authContext.userHasPermission('manageClients')) {
    allowedRoutes.push('admin.permissions');
  }

  if (authContext.userHasPermission('manageServices')) {
    allowedRoutes.push('admin.groupServices');
  }

  if (authContext.userHasPermission('manageProvidedServices')) {
    allowedRoutes.push('admin.services');
  }

  if (authContext.getFeatureToggle('dailyDataExports')
    && authContext.isSuperuser()) {
    allowedRoutes.push('admin.dataExports');
  }

  if (authContext.getFeatureToggle('deviceManagement')
    && authContext.userHasPermission('manageDevices')) {
    allowedRoutes.push('admin.deviceManagement', 'admin.deviceManagement.state');
  }

  if (authContext.isSuperuser()) {
    allowedRoutes.push(
      'admin.integrations',
      'admin.integration-terms', // TODO needed - this is controller route?
      'admin.reports',
      'admin.reports.new',
    );
    if (authContext.getFeatureToggle('dataRetention') && authContext.isSuperuser()) {
      allowedRoutes.push(
        'admin.data_retention',
        'admin.data_retention.pending',
        'admin.data_retention.deleted',
        'admin.data_retention.report',
      );
    }
  }

  if (authContext.currentUser.role === 'carer') {
    allowedRoutes.push(
      'admin.reports',
      'admin.reports.new',
    );
  }

  return allowedRoutes;
};
