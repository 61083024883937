import useUserAuthStore, { AuthApiResponse } from '@/_shared/store/userAuth';
import { defineStore } from 'pinia';
import { computed, ref } from 'vue';
import apiClient from '@/_shared/services/apiClient';
import { CurrentUser } from '@/_shared/types/currentUser';
import { FeatureToggles } from '@/_shared/types/featureToggles';
import {
  BasePermissions, CalculatedPermissions,
  PermissionsSubject, VirtualPermissions,
} from '@/_shared/types/permissions';
import { ToastType } from '@/_shared/services/nourishHelpers';
import { OrganisationUnit, OrganisationUnitAngular } from '@/_shared/types/organisationUnit';

declare global {
  interface Window {
    currentOrganisationUnit: Partial<OrganisationUnitAngular>;
    t: (key: string, interpolations?: Record<string, unknown>) => string;
    currentPerson: CurrentUser;
    I18n_locale: string,
    I18n: object;
    Intercom: (action: string, event: string) => void; // TODO fix failing test after the merge
    helpers: {
      toast: (message: string, type: ToastType, duration?: number) => void;
    }
    vueUnsavedMessage: string | null;
    reloadDataPoints: boolean | null;
    forceAngularToReloadState: boolean;
    loadingCounter: number;
    familyPortal: boolean;
    vueAppRoute: boolean;
    hasCamera: boolean | null;
    environment: string;
  }
}

// TODO cleanup after all store refactorings
// export interface CurrentPerson {
//   id: number;
//   language: string;
//   orgUnitId?: number;
//   orgId?: number;
//   carer?: Carer;
//   name?: string;
// }

// interface Carer {
//   superuser: boolean;
// }

const useUserStore = defineStore('user', () => {
  // TODO for standalone Vue
  function logout() {
    // TODO in standalone Vue ???
    // this.invalidate()
    const { authLogout } = useUserAuthStore();
    authLogout();
  }

  // TODO for standalone Vue
  async function login(email: string, password: string) {
    // HACK just for local testing
    const { authLogin, authLogout } = useUserAuthStore();
    try {
      const authResponse = await authenticateUser(email, password);
      return authLogin(authResponse);
    } catch {
      authLogout();
      return false;
    }
  }

  // TODO merge into the user store - later
  // TODO reload state when currentOrganisationUnit is changed

  const permissions = ref({} as BasePermissions);
  const featureToggles = ref({} as FeatureToggles);
  const currentUser = ref({} as CurrentUser);
  const currentOrganisationUnit = ref({} as OrganisationUnit);
  const rolesWithConfidentialViewAccess = ref([] as string[]);
  // TODO check if I need double function
  const currentOrgId = computed(() => currentOrganisationUnit.value.organisationId);
  const currentOrgUnitId = computed(() => currentOrganisationUnit.value.id);
  const currentDeviceUuid = ref(window.localStorage.getItem('deviceUUID'));

  async function fetch(forceReload = false) {
    // TODO implement caching/expiration ???
    // TODO this should take current user config from nourishAuth store in standalone Vue

    if (forceReload) {
      // if (useUserAuthStore().loggedIn()) {
      // console.log('userStore - fetching');
      try {
        const responseData = await fetchNourishStore();
        permissions.value = responseData.permissions;
        featureToggles.value = responseData.featureToggles;
        currentUser.value = responseData.currentUser;
        currentOrganisationUnit.value = responseData.currentOrganisationUnit;
        rolesWithConfidentialViewAccess.value = responseData.rolesWithConfidentialViewAccess;
        // TODO setup watch effect???
        // i18n.global.locale.value = newValue.currentPerson.language;
      } catch (ex) {
        console.log('Failed to load nourish store state');
      }
      // } else {
      //   console.log('userStore - not logged in');
      // }
    }
  }

  async function ensureLoaded() {
    const anyStateEmpty = !Object.keys(permissions.value || {}).length
      || !Object.keys(featureToggles.value || {}).length
      || !Object.keys(currentUser.value || {}).length
      || !Object.keys(currentOrganisationUnit.value || {}).length;
    await fetch(anyStateEmpty);
    return anyStateEmpty;
  }

  // TODO keep until we decide we definitively don't need it
  // function checkLoaded(throwError: boolean) {
  //   if (isLoaded.value) {
  //     return true;
  //   }
  //   if (throwError) {
  //     throw new Error('nourish store state is not loaded. Call ensureLoaded() from top-level component');
  //   }
  //   return false;
  // }

  const getFeatureToggle = (featureToggle: keyof FeatureToggles) => featureToggles.value[featureToggle] || false;

  // TODO remove after the function is implemented
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const userCan = (action: keyof VirtualPermissions, subject: PermissionsSubject) => {
    // this is lifted Angular code
    // TODO uncomment and fix if when needed
    // if (subject instanceof Carer) {
    //   return carerAbilities(action, subject);
    // } else if (subject instanceof OrganisationUnitState) {
    //   return carerOrganisationUnitAbilities(action, subject);
    // }

    if (action === 'manageInteractionsForServiceId' && (typeof subject === 'number')) {
      if (window.familyPortal) { return false; }

      return permissions.value?.updateableServiceIds?.indexOf(subject) !== -1;
    }

    if (action === 'readInteractionsForServiceId' && (typeof subject === 'number')) {
      return permissions.value?.readableServiceIds?.indexOf(subject) !== -1;
    }

    // if (action === 'read') {
    //   if (subject === 'dashboardPage') {
    //     return isSuperuser();
    //   }
    //   if (subject === 'rolesPage') {
    //     return isSuperuser();
    //   }
    //   if (subject === ShiftAllocation || subject === 'ShiftAllocation') {
    //     return !!person.carer;
    //   }
    // }

    if (subject === 'gpConnect') {
      if (action === 'full') {
        return !isSuperuser()
          && !isImpersonated()
          && permissions.value.gpConnectAccess === 'full';
      }
      if (action === 'nonClinical') {
        return !isSuperuser()
          && !isImpersonated()
          && permissions.value.gpConnectAccess === 'non_clinical';
      }
    }

    if (subject === 'londonCareRecord') {
      if (action === 'registeredManager' || action === 'seniorCarer' || action === 'admin' || action === 'any') {
        return !isSuperuser()
          && !isImpersonated()
          && permissions.value.londonCareRecordAccess !== null
          && permissions.value.londonCareRecordAccess !== 'no_access';
      }
    }

    if (action === 'update') {
      //   if (subject instanceof CurrentPerson) {
      //     if (subject.id === person.id) {
      //       if (subject.role === 'informal_carer') {
      //         return false;
      //       }
      //     }
      //   }
      //   if (subject instanceof Client) {
      //     if (currentApp.isFamilyPortal()) { return false; }
      //     return canUpdateClient(subject.id);
      //   }
      //   if (subject instanceof Relation) {
      //     if (currentApp.isFamilyPortal()) { return false; }
      //     return currentPermissions.fetch().manageRelations;
      //   }
      //   if (subject === 'ShiftAllocation') {
      //     return isSuperuser();
      //   }
      // } else if (action === 'updateCarePlan') {
      //   if (subject instanceof Client) {
      //     return currentPermissions.fetch().canEditCareplan && canUpdateClient(subject.id);
      //   }
      //   return false;
      // } else if (action === 'promote') {
      //   if (subject instanceof ProvidedService) {
      //     return canPromoteProvidedServices();
      //   }
      // } else if (action === 'manage') {
      //   if ((subject === ProvidedService || subject === 'ProvidedService')
      //     || (subject === Report || subject === 'Report')
      //     || (subject === Shift || subject === 'Shift')
      //     || (subject === ShiftAllocation || subject === 'ShiftAllocation')
      //   ) {
      //     return (
      //       isSuperuser() || (subject === Report || subject === 'Report')
      //     );
      //   }
      //   if (subject === 'Service') {
      //     return isSuperuser();
      //   }
      //   if (subject instanceof OrganisationUnit) {
      //     const currentOrgUnit = _.find(person.organisationUnits, (ou) => ou.id === subject.id);
      //
      //     return currentOrgUnit && (person.carer && person.carer.superuser);
      //   }
      //   if (subject === 'DataExport') {
      //     return isSuperuser();
      //   }
    } else if (action === 'create') {
      // if (subject === 'Relation') {
      //   return currentPermissions.fetch().manageRelations;
      // }
      if (subject === 'Client') {
        return permissions.value.manageClients;
      }
    }

    return false;
  };

  function userHasPermission(action: keyof BasePermissions | keyof CalculatedPermissions): boolean {
    if (action === 'createInteractions') {
      return (!!permissions.value.updateableServiceIds && !!permissions.value.updateableServiceIds.length);
    }
    if (action === 'editAfterInteractionClose') {
      return permissions.value.interactionSettingsAccess;
    }
    const can = permissions.value[action];
    return (typeof can === 'boolean') ? can : false;
  }

  function isSuperuser() {
    return currentUser.value.carer?.superuser ?? false;
  }

  function isImpersonated() {
    return currentUser.value.isImpersonated;
  }

  window.hasCamera = null;

  return {
    login,
    logout,
    currentUser,
    currentOrganisationUnit,
    rolesWithConfidentialViewAccess,
    currentOrgId,
    currentOrgUnitId,
    currentDeviceUuid,
    ensureLoaded,
    userCan,
    userHasPermission,
    isSuperuser,
    isImpersonated,
    getFeatureToggle,
    permissions,
    featureToggles,
  };
});

export default useUserStore;

// API - move to separate file if there are further functions here

interface NourishStoreState {
  permissions: BasePermissions;
  featureToggles: FeatureToggles;
  currentUser: CurrentUser;
  currentOrganisationUnit: OrganisationUnit;
  rolesWithConfidentialViewAccess: string[];
}

function fetchNourishStore() {
  return apiClient.get<NourishStoreState>('me/nourish_store');
}

function authenticateUser(email: string, password: string) {
  return apiClient.post<AuthApiResponse>('/api/v3/auth/person', { person: { email, password } });
}
