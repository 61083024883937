import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { key: 2 }
const _hoisted_2 = {
  key: 0,
  class: "v-instance-group"
}
const _hoisted_3 = {
  key: 3,
  class: "v-instance-group"
}

import {
  computed, ref, onMounted, onUnmounted, onBeforeUnmount, Ref, watchEffect,
  watch,
} from 'vue';
import BodyMapFilter from '@/timeline/components/BodyMap/BodyMapFilter/BodyMapFilter.vue';
import BodyMapImage from '@/timeline/components/BodyMap/BodyMapImage.vue';
import ShowLocations from '@/timeline/components/BodyMap/ShowLocations.vue';
import BodyMapListEntry from '@/timeline/components/BodyMap/BodyMapListEntry.vue';
import { clientStore } from '@/_shared/store/clients';
import useBodyMapLocationsHelper from '@/timeline/components/BodyMap/useBodyMapLocationsHelper';
import useServiceFilteringStore from '@/_shared/store/serviceFiltering';
import { storeToRefs } from 'pinia';
import type { SkinInstance } from '@/_shared/types/NourishInstance';
import use from '@/_shared/compositionApi';
import type { DropdownFilterOption } from '@/_shared/types/dropdownFilterOption';
import useTimelineFiltersStore from '@/_shared/store/timelineFilters';
import instancesHelper from '@/_shared/helpers/useInstancesHelper';
import { instanceStateTranslations } from '@/_shared/services/instanceFilterHelper';


export default /*@__PURE__*/_defineComponent({
  __name: 'BodyMapTimeline',
  props: {
  clientId: {
    type: String,
    required: true,
  },
  listOnly: {
    type: Boolean,
    optional: true,
    default: false,
  },
},
  emits: ['selectInstance'],
  setup(__props, { emit: __emit }) {

const props = __props;

const emit = __emit;

const { translate } = use.helpers();

const { groupInstancesByState, filterGroupedInstances } = instancesHelper();

const timelineFiltersStore = useTimelineFiltersStore();
const { isTimelineFilterOpen, timelineFilters, timelineSearchTerm } = storeToRefs(timelineFiltersStore);
const {
  setTimelineFilters,
  setTimelineFilterText,
  filterTimelineInstancesByStateAndSubtype,
} = timelineFiltersStore;

const skinInstances = computed(() => clientStore.skinInstances(+props.clientId).value);
const groupedInstances = computed(() => groupInstancesByState<SkinInstance>(skinInstances.value, 'body_map.state'));

const {
  getInstanceLocations, defaultSide,
} = useBodyMapLocationsHelper().forSkinInstances(skinInstances);

// Set default filter values.
setTimelineFilters({
  side: { name: '', codename: '' },
});

const showLocations = ref(true);

const toggleShowLocations = () => {
  showLocations.value = !showLocations.value;
};

const showInstanceForSide = (side: string, instanceSide: string) => side === 'all' || [side, 'both'].includes(instanceSide);
const showInstanceForSearchTerm = (instance: SkinInstance) => {
  let instanceSearchable = instance.reference;
  if (instance.locationInfo) {
    if (instance.locationInfo.count) {
      instanceSearchable += ` ${instance.locationInfo.count.toString()}`;
    }
    instanceSearchable += ` ${translate(`datasets.${instance.metadata.location}`)}`;
    instanceSearchable += ` ${translate(`datasets.${instance.metadata.specificType}`)}`;
  }
  return instanceSearchable.toLowerCase().includes(timelineSearchTerm.value.toLowerCase());
};

const serviceFilteringStore = useServiceFilteringStore();
const { bodyMapTimelineMounted } = storeToRefs(serviceFilteringStore);

// todo need to be moved to plus button / or just hide it
// todo   remove code from onmounted , to be able to use keepAlive -->
onMounted(() => {
  setTimelineFilterText([
    `${instanceStateTranslations('new', 'body_map.state')} & ${instanceStateTranslations('active', 'body_map.state')}`,
    translate('filter.all'),
  ]);
  // If we're coming from listOnly mode we don't want to trigger this.
  if (!props.listOnly) {
    bodyMapTimelineMounted.value = true;
  }
  // if (route.name === 'client.timeline.service_selector' && !newSkinInstanceServices.value.length) {
  //   await redirectToTimeline(router, route);
  // }
});

onUnmounted(() => {
  // If we're coming from listOnly mode we don't want to trigger this.
  if (!props.listOnly) {
    bodyMapTimelineMounted.value = false;
  }
});

const currentSide = ref(timelineFilters.value.side.name.length ? timelineFilters.value.side.name : defaultSide.value);
const handleSideChange = (newSide: string) => {
  currentSide.value = newSide;
  setTimelineFilters({ side: { name: newSide, codename: newSide } });
};

const currentInstances = computed(() => {
  const filterFunction = (skinInstance: SkinInstance) => {
    const instanceLocation = skinInstance.locationInfo?.side;
    if (!instanceLocation) {
      return currentSide.value === 'all';
    }
    return showInstanceForSide(currentSide.value, instanceLocation) && showInstanceForSearchTerm(skinInstance);
  };
  return filterGroupedInstances(groupedInstances.value, filterFunction);
});

const currentInstancesIds = computed(() => currentInstances.value.map(({ instances }) => instances.map(({ id }) => id)).flat());

const filteredInstances = computed(() => {
  const filterFunction = (instance: SkinInstance) => filterTimelineInstancesByStateAndSubtype(instance);
  return filterGroupedInstances(currentInstances.value, filterFunction);
});

const allSideFilters = ref([] as DropdownFilterOption[]);
watchEffect(() => {
  const deepCopy = (bodyMapFilterOption: DropdownFilterOption) => JSON.parse(JSON.stringify(bodyMapFilterOption));
  if (currentSide.value !== 'all') return;
  allSideFilters.value = [deepCopy(timelineFilters.value.state), deepCopy(timelineFilters.value.subType)];
  if (groupedInstances.value.length === 1) {
    setTimelineFilters({
      state: { name: '', codename: groupedInstances.value[0]?.state },
    });
  }
});

const instancesCount = computed(() => {
  const filterFunction = (skinInstance: SkinInstance) => filterTimelineInstancesByStateAndSubtype(skinInstance) && showInstanceForSearchTerm(skinInstance);
  const instancesToCount = filterGroupedInstances(groupedInstances.value, filterFunction);
  let instanceCount = 0;
  instancesToCount.forEach((instancesAndState) => {
    instanceCount += instancesAndState.instances.length;
  });
  return instanceCount;
});

const filteredInstanceLocations = computed(() => {
  let allSkinInstances: SkinInstance[] = [];
  filteredInstances.value.forEach((instancesAndState) => {
    allSkinInstances = allSkinInstances.concat(instancesAndState.instances);
  });

  return getInstanceLocations(allSkinInstances);
});

watch(timelineFilters, () => {
  let sideText = translate('common.all');
  if (!['all', ''].includes(timelineFilters.value.side.name)) {
    sideText = translate(`body_map.${timelineFilters.value.side.name}`);
  }
  const filters = [timelineFilters.value.state.name, timelineFilters.value.subType.name, sideText];
  setTimelineFilterText(filters);
});

onBeforeUnmount(() => {
  useTimelineFiltersStore().$reset();
});

const listOnlyFilteredSkinInstances = computed(() => {
  const newInstances: SkinInstance[] = [];
  const missedInteractionsInstances: SkinInstance[] = [];
  const cancelledInteractionsInstances: SkinInstance[] = [];
  groupedInstances.value.forEach((instanceGroup) => {
    instanceGroup.instances.forEach((instance) => {
      if (instance.state === 'new') {
        // Sorted by instance creation date.
        newInstances.push(instance);
      } else if (instance?.missedInteractions?.length) {
        // Active wounds with missed interactions (planned in the past).
        missedInteractionsInstances.push(instance);
      } else if (instance.state === 'active' && (
        !instance.interactions.some((interaction) => interaction.state === 'planned')
      )) {
        // Active wounds with no planned interactions (1 or more cancelled interactions and no planned).
        cancelledInteractionsInstances.push(instance);
      }
    });
  });
  // Sorted by instance creation date.
  newInstances.sort((a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime());
  missedInteractionsInstances.sort((a, b) => {
    const aPlanned = a.interactions.filter((i) => i.state === 'planned');
    const bPlanned = b.interactions.filter((i) => i.state === 'planned');
    const aEarliest = aPlanned.length ? new Date(Math.min(...aPlanned.map((i) => new Date(i.finishAt ? i.finishAt : '').getTime()))).getTime() : new Date().getTime();
    const bEarliest = bPlanned.length ? new Date(Math.min(...bPlanned.map((i) => new Date(i.finishAt ? i.finishAt : '').getTime()))).getTime() : new Date().getTime();
    return aEarliest - bEarliest;
  });
  // Sorted by wound creation date.
  cancelledInteractionsInstances.sort((a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime());
  return [...newInstances, ...missedInteractionsInstances, ...cancelledInteractionsInstances];
});
const showListOnlySection = computed(() => (props.listOnly && listOnlyFilteredSkinInstances.value.length > 0));

const filterDiv : Ref<HTMLElement|null> = ref(null);


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _withDirectives(_createElementVNode("div", {
      ref_key: "filterDiv",
      ref: filterDiv,
      class: "v-instances-filter-container"
    }, [
      _createVNode(BodyMapFilter, {
        instancesIds: currentInstancesIds.value,
        clientId: +props.clientId
      }, null, 8, ["instancesIds", "clientId"])
    ], 512), [
      [_vShow, _unref(isTimelineFilterOpen)]
    ]),
    (!__props.listOnly)
      ? (_openBlock(), _createBlock(ShowLocations, {
          key: 0,
          onClick: toggleShowLocations
        }))
      : _createCommentVNode("", true),
    (!__props.listOnly && filterDiv.value)
      ? (_openBlock(), _createBlock(BodyMapImage, {
          key: 1,
          showBodyMapImage: showLocations.value,
          "left-panel": true,
          "instance-locations": filteredInstanceLocations.value,
          "side-to-show": currentSide.value,
          "show-side-selector": _unref(isTimelineFilterOpen),
          teleportFilterTo: filterDiv.value,
          onSideChanged: handleSideChange,
          "instance-count": instancesCount.value
        }, null, 8, ["showBodyMapImage", "instance-locations", "side-to-show", "show-side-selector", "teleportFilterTo", "instance-count"]))
      : _createCommentVNode("", true),
    (!__props.listOnly)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(filteredInstances.value, (instanceGroup) => {
            return (_openBlock(), _createElementBlock("div", {
              key: instanceGroup.state
            }, [
              (instanceGroup.instances.length)
                ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                    _createElementVNode("h4", {
                      class: _normalizeClass(["v-body-map-section", `v-${instanceGroup.state.toLowerCase()}`])
                    }, _toDisplayString(instanceGroup.label), 3),
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(instanceGroup.instances, (instance) => {
                      return (_openBlock(), _createBlock(BodyMapListEntry, {
                        key: instance.id,
                        reference: true,
                        instance: instance,
                        sideToShow: currentSide.value,
                        showLocationIcon: true,
                        onSelectInstance: ($event: any) => (emit('selectInstance', instance))
                      }, null, 8, ["instance", "sideToShow", "onSelectInstance"]))
                    }), 128))
                  ]))
                : _createCommentVNode("", true)
            ]))
          }), 128))
        ]))
      : (showListOnlySection.value)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(listOnlyFilteredSkinInstances.value, (instance) => {
              return (_openBlock(), _createBlock(BodyMapListEntry, {
                key: instance.id,
                reference: true,
                instance: instance,
                sideToShow: currentSide.value,
                showLocationIcon: false,
                onSelectInstance: ($event: any) => (emit('selectInstance', instance))
              }, null, 8, ["instance", "sideToShow", "onSelectInstance"]))
            }), 128))
          ]))
        : _createCommentVNode("", true)
  ], 64))
}
}

})