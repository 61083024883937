import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, mergeProps as _mergeProps, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "v-navigation-router-item" }
const _hoisted_2 = ["href", "onClick"]
const _hoisted_3 = { class: "v-navigation-text" }
const _hoisted_4 = { class: "v-navigation-text-inner-div" }

import { RouterLink } from 'vue-router';
import { useBreakpoints } from '@vueuse/core';
import { vBaseTooltip } from '@/_shared/directives/';
import BaseIcon from '@/_shared/components/general/BaseIcon.vue';
import useNavLinkActive from '@/_shared/Composables/useNavLinkActive';


export default /*@__PURE__*/_defineComponent({
  __name: 'BaseNavigationItem',
  props: {
  text: {
    type: String,
    required: true,
  },
  icon: {
    type: String,
    required: true,
  },
  ...useNavLinkActive().navLinkProps,
},
  setup(__props) {


const breakpoints = useBreakpoints({
  xs: 480,
  sm: 800,
  md: 992,
  lg: 1224,
});
const toHide = breakpoints.greater('lg');

const props = __props;

const { isNavItemActive } = useNavLinkActive()
  .getActivationCheckers(props);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_unref(RouterLink), _mergeProps({ to: "" }, _ctx.$props, { custom: "" }), {
      default: _withCtx(({ href, navigate }) => [
        _withDirectives((_openBlock(), _createElementBlock("div", _mergeProps(_ctx.$attrs, {
          class: ["v-base-navigation-item", {active:  _unref(isNavItemActive) }],
          href: href,
          onClick: navigate
        }), [
          _createElementVNode("div", {
            class: _normalizeClass(["v-navigation-item", `sp-${props.text.replace(' ', '-').toLowerCase()}`])
          }, [
            _createVNode(BaseIcon, {
              class: "v-flex-icon",
              noHoverBackground: "",
              foregroundHoverColor: "grey",
              name: props.icon
            }, null, 8, ["name"]),
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("div", _hoisted_4, _toDisplayString(props.text), 1)
            ]),
            _renderSlot(_ctx.$slots, "right-col-icon")
          ], 2)
        ], 16, _hoisted_2)), [
          [_unref(vBaseTooltip), {tooltipText:props.text, hide: _unref(toHide) }]
        ])
      ]),
      _: 3
    }, 16)
  ]))
}
}

})